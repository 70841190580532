import s from './styles.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'

const FEEDBACKS = [
  {
    id: 1,
    text: "This is the best thing for romance and drama lovers! Short episodes are perfect for quick viewing, and the emotions are always at their peak",
  },
  {
    id: 2,
    text: "I love the romance shows!! Every story is so engaging, I just can’t stop watching. I feel like the main character!",
  },
  {
    id: 3,
    text: "I really like these stories of wore wolves, it keeps me captivated and awake all night long.",
  },
  {
    id: 4,
    text: "The plots always keep me on edge, and the romantic stories leave me breathless",
  },
]

const SectionTestimonials = () => {
  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.headingBlock}>
          <span className={s.label}>Testimonials</span>

          <div className={s.headingContent}>
            <h2 className={s.heading}>Testimonials from Our Viewers</h2>
            <p className={s.description}>
              <span>My Drama app</span> has received numerous recognitions, a testament 
              to the hard work and creativity that go into every aspect of the platform 
              series, from the initial concept to the final product.
            </p>
          </div>
        </div>
      </div>

        <Swiper
          className={s.swiper}
          spaceBetween={20}
          slidesPerView="auto"
          freeMode={true}
          grabCursor={true}
          loop
          breakpoints={{
            1440: {
              spaceBetween: 30,
            },
          }}
        >
          {FEEDBACKS.map(({ id, text}) => (
            <SwiperSlide key={id} className={s.swiperSlide}>
              <img 
                className={s.stars}
                src="/images/myDrama/stars.webp"
                alt="Rating stars" 
              />
              <p className={s.feedback}>{text}</p>
            </SwiperSlide>
          ))}
        </Swiper>
    </section>
  )
}

export default SectionTestimonials