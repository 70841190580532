import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import { components } from './components'

const config = {
	initialColorMode: 'dark',
}

const breakpoints = createBreakpoints({
	sm: '0em',
	md: '834px',
	lg: '1191px',
})

const overrides = {
	config,
	components,
	breakpoints,
}

export default extendTheme(overrides)
