import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const InstaIcon = createIcon({
	viewBox: '0 0 22 22',
	path: (
		<path
			d="M16.3383 11.0005V10.9999C16.3383 9.94409 16.0252 8.91196 15.4386 8.03407C14.852 7.15617 14.0183 6.47193 13.0428 6.06788C12.0674 5.66383 10.994 5.55812 9.95845 5.7641C8.9229 5.97008 7.97169 6.47851 7.2251 7.2251C6.47851 7.97169 5.97008 8.9229 5.7641 9.95845C5.55812 10.994 5.66383 12.0674 6.06788 13.0428C6.47193 14.0183 7.15617 14.852 8.03407 15.4386C8.91196 16.0252 9.94409 16.3383 10.9999 16.3383L11.0005 16.3383C12.4157 16.3367 13.7724 15.7738 14.7731 14.7731C15.7738 13.7724 16.3367 12.4157 16.3383 11.0005ZM13.7255 11.0005C13.7254 11.5394 13.5656 12.0661 13.2662 12.5142C12.9667 12.9624 12.541 13.3118 12.043 13.518C11.5449 13.7243 10.9969 13.7783 10.4682 13.6732C9.93947 13.568 9.45381 13.3084 9.07263 12.9272C8.69145 12.546 8.43186 12.0604 8.32669 11.5317C8.22153 11.0029 8.2755 10.4549 8.4818 9.95688C8.68809 9.45884 9.03744 9.03316 9.48566 8.73367C9.93384 8.43421 10.4607 8.27436 10.9997 8.27432C11.7224 8.27525 12.4151 8.56273 12.9261 9.07371C13.4372 9.58479 13.7247 10.2777 13.7255 11.0005ZM20.5799 15.4346C20.5784 16.7987 20.0358 18.1066 19.0712 19.0712C18.1066 20.0358 16.7987 20.5784 15.4346 20.5799H6.56529C5.20113 20.5784 3.89328 20.0358 2.92867 19.0712C1.96413 18.1066 1.42154 16.7989 1.41992 15.4348V6.56501C1.42154 5.20095 1.96413 3.89321 2.92867 2.92867C3.89321 1.96413 5.20095 1.42154 6.56501 1.41992H15.4348C16.7989 1.42154 18.1066 1.96413 19.0712 2.92867C20.0358 3.89328 20.5784 5.20113 20.5799 6.56529V15.4346ZM17.9511 5.75832C17.9511 5.4202 17.8509 5.08966 17.663 4.80852C17.4751 4.52738 17.2081 4.30825 16.8958 4.17886C16.5834 4.04946 16.2396 4.01561 15.908 4.08157C15.5764 4.14754 15.2717 4.31036 15.0327 4.54945C14.7936 4.78854 14.6307 5.09317 14.5648 5.4248C14.4988 5.75643 14.5327 6.10017 14.6621 6.41256C14.7915 6.72495 15.0106 6.99195 15.2917 7.1798C15.5729 7.36766 15.9034 7.46792 16.2415 7.46792C16.6949 7.46792 17.1298 7.2878 17.4504 6.96719C17.771 6.64658 17.9511 6.21174 17.9511 5.75832Z"
			fill="white"
			stroke="white"
		/>
	),
})
