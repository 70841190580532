import React, { useMemo } from 'react'
import s from './styles.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Mousewheel, Pagination } from 'swiper'
import { ArrowIcon } from '../../../shared/icons/ArrowIcon'
import useWindowSize from '../../../hooks/useWindowSize'

const photo = ['1', '2', '3', '4', '5', '6', '7', '8']
const SectionHero = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
	return (
		<section className={s.heroWrapper}>
			<Swiper
				slidesPerView="auto"
				speed={1500}
				freeMode={true}
				loop
				className={s.aboutSlider}
				modules={[FreeMode, Pagination, Mousewheel]}
				allowSlidePrev={true}
				allowSlideNext={true}
				mousewheel={{ forceToAxis: true }}
			>
				{photo.map((item, index) => (
					<SwiperSlide
						key={item}
						className={s.heroPhotoItem}
						style={{ width: 'auto' }}
					>
						<img
							className={s.heroPhoto}
							src={`/images/heroSectionPhoto/${item}.png`}
							alt="team photo"
						/>
						{!isMobile && index === 4 && (
							<a
								href="mailto:info@holywater.tech"
								className={s.heroButton}
								style={{
									margin: 0,
									textAlign: 'start',
									padding: '36px 68px',
									position: 'relative',
								}}
							>
								Let&apos;s discuss{' '}
								<ArrowIcon
									position="absolute"
									top="65px"
									right="30px"
									w="24px"
									h="24px"
								/>
							</a>
						)}
					</SwiperSlide>
				))}
			</Swiper>

			{isMobile && (
				<a href="mailto:info@holywater.tech" className={s.heroButton}>
					Let&apos;s discuss <ArrowIcon ml="15px" w="24px" h="24px" />
				</a>
			)}
		</section>
	)
}

export default SectionHero
