export const buttonStyles = {
	baseStyle: {
		fontFamily: 'e-Ukraine, sans-serif',
		fontStyle: 'normal',
		fontWeight: 700,
		borderRadius: '48px',
		fontSize: '16px',
		color: '#fff',
		lineHeight: '140%',

		_disabled: {
			opacity: 0.7,
			cursor: 'not-allowed',
		},
		_hover: {
			bg: '#FEF151',
			color: '#191818',
			_disabled: {
				bg: '#644ded',
				opacity: 0.7,
				cursor: 'not-allowed',
			},
		},
		_focus: {
			boxShadow: 'none',
		},
	},
	sizes: {
		sm: {
			padding: '18.5px 41.5px 19.5px',
		},
		md: {
			padding: '15.5px 44px',
			minHeight: 50,
		},
		lg: {
			padding: '24px 93px',
		},
	},
	variants: {
		blue: {
			bg: '#3873EE',
			color: '#fff',
			_hover: {
				bg: '#234CDC;',
				color: '#fff',
				_disabled: {
					bg: '#234CDC;',
					opacity: 0.7,
					cursor: 'not-allowed',
				},
			},
		},
		dark: {
			bg: '#191818;',
			color: '#fff',
			border: '1px solid #323232',
			_hover: {
				bg: '#191818;',
				color: '#fff',
				_disabled: {
					bg: '#191818;',
					opacity: 0.7,
					cursor: 'not-allowed',
				},
			},
		},
		white: {
			bg: '#fff',
		},
		yellow: {
			bg: '#FEF151',
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'blue',
	},
}
