import { accordionStyles } from './accordion'
import { buttonStyles } from './button'
import { headingStyles } from './heading'
import { popoverStyles } from './popover'
import { textStyles } from './text'
import { inputStyles } from './input'
import { checkboxStyles } from './checkbox'
import { textareaStyles } from './textarea'

export const components = {
	Accordion: accordionStyles,
	Button: buttonStyles,
	Popover: popoverStyles,
	Heading: headingStyles,
	Text: textStyles,
	Input: inputStyles,
	Checkbox: checkboxStyles,
	Textarea: textareaStyles,
}
