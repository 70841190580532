const members = [
	{
		id: 0,
		fullName: 'Bogdan Nesvit',
		position: 'CEO',
		img: '/images/teamPage/members/bogdan-nesvit.png',
	},
	{
		id: 1,
		fullName: 'Anatolii Kasianov',
		position: 'CTO',
		img: '/images/teamPage/members/anatolii-kasianov.png',
	},
	// {
	//     id: 3,
	//     fullName: 'Vitalii Reshetnyk',
	//     position: 'Project Manager',
	//     img: '/images/teamPage/members/vitalii-reshetnyk.png'
	// },
	// {
	//     id: 4,
	//     fullName: 'Maksym Kotsiuba',
	//     position: 'QA Engineer',
	//     img: '/images/teamPage/members/maksym-kotsiuba.png'
	// },
	// {
	//     id: 5,
	//     fullName: 'Myla Mishchenko',
	//     position: 'Narrative Designer',
	//     img: '/images/teamPage/members/myla-mishchenko.png'
	// },
	// {
	//     id: 6,
	//     fullName: 'Yelyzaveta Popova',
	//     position: 'Art Lead',
	//     img: '/images/teamPage/members/yelyzaveta-popova.png'
	// },
	// {
	//     id: 7,
	//     fullName: 'Zakhar Azatian',
	//     position: 'Product Manager',
	//     img: '/images/teamPage/members/zakhar-azatian.png'
	// },
	// {
	//     id: 8,
	//     fullName: 'Ziong L',
	//     position: 'IOS Developer',
	//     img: '/images/teamPage/members/ziong-l.png'
	// },
	// {
	//     id: 9,
	//     fullName: 'Daria Koshova',
	//     position: 'Business Analyst',
	//     img: '/images/teamPage/members/daria-koshova.png'
	// },
	// {
	//     id: 10,
	//     fullName: 'Iryna Melnychenko',
	//     position: 'Narrative Designer',
	//     img: '/images/teamPage/members/iryna-melnychenko.png'
	// },
	// {
	//     id: 11,
	//     fullName: 'Ruslana Polonska',
	//     position: 'Head of Creative Team',
	//     img: '/images/teamPage/members/ruslana-polonska.png'
	// },
	// {
	//     id: 0,
	//     fullName: 'Stanislav Kravets ',
	//     position: 'Marketing Specialist ',
	//     img: '/images/teamPage/members/stanislav-kravets.png'
	// }
]

export default members
