import s from './styles.module.scss'

const FEATURES = [
  {
    id: 1,
    src: "/images/myDrama/feature_1.webp",
    alt: "Phone icon",
    heading: "Each video is shot and produced with cinematic quality.",
    text: (
      <>
        Our visuals are as compelling as the stories themselves. This isn&apos;t just about adapting 
        content to video — it&apos;s about enhancing it in ways only a dedicated platform series can.
      </>
    ),
  },
  {
    id: 2,
    src: "/images/myDrama/feature_2.webp",
    alt: "Shift arrows icon",
    heading: (
      <>
        <span>My Drama app</span> seamlessly integrates with other HOLYWATER products.
      </>
    ),
    text: (
      <>
        For instance, if a viewer reads a book through HOLYWATER and loved it, My Drama might recommend 
        the video adaptation, complete with additional content that expands on the original story.
      </>
    ),
  },
  {
    id: 3,
    src: "/images/myDrama/feature_3.webp",
    alt: "Command icon",
    heading: "HOLYWATER team is already working on developing and implementing a new feature – AI Companion.",
    text: (
      <>
        Imagine a world where your favorite fictional characters come to life. With AI Companion, 
        you can chat with beloved heroes, heroines, and villains from our series.
      </>
    ),
  },
  {
    id: 4,
    src: "/images/myDrama/feature_4.webp",
    alt: "Play icon",
    heading: "7 unique series already available in the app.",
    text: null,
  },
  {
    id: 5,
    src: "/images/myDrama/feature_5.webp",
    alt: "Window icon",
    heading: "The most flexible payment method.",
    text: (
      <>
        The viewer can either buy a monthly subscription or pay to watch each episode separately. 
        The first episodes are always free to watch, so that the viewer can understand whether 
        this content is interesting for them.
      </>
    ),
  },
  {
    id: 6,
    src: "/images/myDrama/feature_6.webp",
    alt: "Stack icon",
    heading: "HOLYWATER focus on delivering the best possible UX.",
    text: (
      <>
        The user-friendly interface of the My Drama app makes it easy to navigate, find new 
        series, and continue watching where the viewer left off.
      </>
    ),
  },
]

const SectionFeatures = () => {
  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.topContentWrapper}>
          <span className={s.label}>Features</span>
          <img 
            className={s.myDramaImage}
            src="/images/myDrama/features_img.webp"
            alt="My Drama app" 
          />
          <div className={s.textBlock}>
            <h2 className={s.heading}>Unique Features of My&nbsp;Drama</h2>
            <p className={s.text}>
              What sets My&nbsp;Drama apart from other <span>series platforms</span> is its combination 
              of high-quality production and personalized content recommendations.
            </p>
          </div>
        </div>

        <div className={s.featuresBlock}>
          {FEATURES.map(({ id, src, alt, heading, text }) => (
            <div key={id} className={s.frature}>
              <img 
                className={s.fratureIcon}
                src={src}
                alt={alt} 
              />

            <div className={s.featureContent}>
              <h3 className={s.featureHeading}>{heading}</h3>
              {text && <p className={s.featureText}>{text}</p>}
            </div>
          </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default SectionFeatures