import React, { useMemo } from 'react'
import {
	Flex,
	Link,
	Box,
	Text,
	Accordion,
	AccordionItem,
	AccordionButton,
	AccordionIcon,
	AccordionPanel,
	useBreakpointValue,
} from '@chakra-ui/react'

import useWindowSize from '../../../hooks/useWindowSize'

const questions = [
	{
		title: 'Хто може взяти участь у конкурсі?',
		answer:
			'До участі в конкурсі запрошуються громадяни/ки України, які є повнолітніми згідно з законодавством України станом на дату початку конкурсу. Виключення становлять особи, які мають або мали публічну антиукраїнську позицію чи підтримували збройну агресію російської федерації проти України, а також особи, котрі перебувають на тимчасово окупованих територіях України, або проживають на території російської федерації чи Республіки Білорусь.',
	},
	{
		title: 'Які твори можна подавати?',
		answer:
			'Твір має бути повністю завершеним на момент подачі, мати не менше 280 000 знаків із пробілами і розподіленим на розділи. Також на твір не повинні розповсюджуватися жодні права третіх осіб, які могли б обмежити його публікацію та використання HOLYWATER і Vivat у рамках конкурсу та в подальшому. Приймаються твори в жанрах: Romance, Paranormal Romance, Romantic Suspense та з сюжетами Alpha Male, Billionaire Romance, Secret Billionaire, Werewolf Romance, Mafia Romance, Hate to Love, Kick-ass Heroine, Forced Marriage.',
	},
	{
		title: 'Які вимоги до обсягу твору?',
		answer: 'Твір має бути обсягом від 280 000 знаків із пробілами.',
	},
	{
		title: 'Якою мовою повинні бути написані твори?',
		answer: 'Твори повинні бути написані українською або англійською мовами.',
	},
	{
		title: 'Які терміни подачі робіт?',
		answer:
			'Автори/ки можуть подати свої готові тексти до 31 серпня 2024 року, а створити та надіслати нові до 30 вересня 2024 року.',
	},
	{
		title: 'Які етапи конкурсу?',
		answer:
			'Усі етапи конкурсу відбуватимуться паралельно. Як тільки ми почнемо отримувати конкурсні роботи, HOLYWATER одразу розпочне їх відбір і тестування за допомогою наших застосунків і програм на основі штучного інтелекту для аналізу потенційної успішності творів. Якщо твори отримають позитивний “вердикт” наших внутрішніх систем, ми завантажимо їх у додаток My Passion, де збиратимемо аналітику від реальних читачів. Переможці/ниці, які отримають контракт від HOLYWATER, можуть бути оголошені як окремим повідомленням електронною поштою, так і 21 жовтня окремою публікацією на сторінках конкурсу.',
	},
	{
		title: 'Як відбувається тестування творів?',
		answer: (
			<>
				Тестування творів відбувається у спеціальному застосунку на основі
				штучного інтелекту, який аналізує твори на основі показників топових
				творів. Також усі твори додатково переглянуть і перевірять редактори/ки
				платформи. Усі відібрані твори будуть опубліковані в застосунку My
				Passion від HOLYWATER для тестування серед реальних читачів (кількість
				переглядів/прочитань, конверсія по переходу на інший розділ, час
				взаємодії тощо).<h1>Як стати письменником</h1>. Надсилаючи роботу,
				автор/ка дає право на публікацію твору у додатках HOLYWATER з метою
				тестування його потенційної успішності. HOLYWATER не монетизує твори до
				підписання контракту з автором/кою.
				<br />
				<br />
				<b>Важливо:</b> ми не використовуємо твори для навчання штучного
				інтелекту або аналітики творчої роботи, наприклад, для написання нового
				тексту, сценарію чи книги. У наших застосунків така функція відсутня або
				обмежена ПЗ.
			</>
		),
	},
	{
		title: 'Що отримають переможці конкурсу?',
		answer: (
			<>
				ТОП-3 переможці/ниці отримають гонорари по 30 000 гривень. Також
				переможці/ниці отримають ексклюзивні контракти з HOLYWATER на
				видавництво, просування та адаптацію їхніх творів на світовій
				digital-арені, а також можливість видати свої книги у видавництві Vivat
				(на розсуд видавництва, організатор НЕ гарантує переможцям укладення
				контрактів із Vivat). Контрактів на співпрацю та розміщення книг на
				платформах HOLYWATER необмежена кількість. Кожен/на автор/ка, чий твір
				пройде випробування читачами застосунку My Passion, має шанс отримати
				запрошення до співпраці з HOLYWATER та отримувати подальші роялті від
				його продажів.
			</>
		),
	},
	{
		title: 'Коли здійснюється виплата призових 30 000 гривень?',
		answer: (
			<>
				Призові будуть виплачені після 21 жовтня впродовж 5 робочих днів
				авторам, котрі посіли перші три місця у рейтингу конкурсу.
			</>
		),
	},
	{
		title: 'Що таке ексклюзивний контракт?',
		answer: (
			<>
				Ексклюзивний контракт передбачає, що права на публікацію та просування
				твору належать HOLYWATER та/або Vivat, і жодні інші компанії чи особи не
				мають таких прав. Автори/авторки отримують щомісячні роялті від усіх
				продажів своїх книг.
			</>
		),
	},
	{
		title:
			'Чи зможу я перетворити свій твір на інші формати, такі як аудіокнига чи сценарій?',
		answer: (
			<>
				Так, найпопулярніші автори/ки платформи матимуть можливість адаптувати
				свої твори у різні формати в межах екосистеми застосунків HOLYWATER:
				аудіокниги, інтерактивні ігри та сценарії для відеосеріалів.
			</>
		),
	},
	{
		title: 'Чи можу я надіслати більше одного твору?',
		answer: (
			<>
				Так, ти можеш надіслати декілька творів, якщо вони відповідають умовам
				конкурсу.
			</>
		),
	},
	{
		title: 'Як я дізнаюсь, що мій твір обраний?',
		answer: (
			<>
				Всі відібрані автори/ки будуть повідомлені через email, який зазначили
				при відправленні форми. Результати також будуть оголошені на сайті
				проєкту та в соціальних мережах HOLYWATER і Vivat 21 жовтня.
			</>
		),
	},
	{
		title: 'Що робити, якщо у мене виникли додаткові запитання?',
		answer: (
			<>
				Ти можеш зв&apos;язатися з нами через{' '}
				<a href="mailto:pyshy@holywater.tech">pyshy@holywater.tech.</a> Ми
				будемо раді відповісти на всі твої запитання.
			</>
		),
	},
]
export const SectionFAQ = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 833, [width])
	return (
		<section
			style={{
				background: '#F3F3F4',
				padding: useBreakpointValue({
					sm: '0 15px',
					md: '0',
					lg: '0 100px',
				}),
				maxWidth: useBreakpointValue({
					sm: '440px',
					md: '794px',
					lg: '1440px',
				}),
				margin: '0 auto',
			}}
		>
			<Link className="anchor" id="faq"></Link>
			<Flex p="120px 0" flexDirection="column">
				<Box textAlign="center">
					<Text
						variant="pishiRegular"
						p="6px 12px 8px"
						color="#D04B6E"
						borderRadius="10px"
						border="1px solid #D04B6E"
						display="inline-block"
						mb="14px"
						fontSize="16px"
					>
						FAQ
					</Text>
					<Text variant="pishiMedium" color="#03060D" mb="42px" fontSize="40px">
						Часті питання
					</Text>
				</Box>
				<Accordion
					allowMultiple
					gap="14px"
					display="flex"
					flexWrap="wrap"
					m="auto"
					justifyContent="center"
				>
					{questions.map(({ title, answer }) => (
						<AccordionItem
							key={title}
							background="#fff"
							borderRadius="10px"
							border="1px solid #fff"
							width={isMobile ? '100%' : '45%'}
							height="fit-content"
						>
							<AccordionButton border={null}>
								<Text
									variant="pishiMedium"
									flex="1"
									fontSize={useBreakpointValue({
										sm: '16px',
										md: '16px',
										lg: '18px',
									})}
									textAlign="left"
									color="#313133"
								>
									{title}
								</Text>
								<AccordionIcon stroke="#313133" color="#313133" />
							</AccordionButton>
							<AccordionPanel
								pb={4}
								as={Text}
								variant="pishiRegular"
								color="#313133"
							>
								{answer}
							</AccordionPanel>
						</AccordionItem>
					))}
				</Accordion>
			</Flex>
		</section>
	)
}
