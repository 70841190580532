import { createSlice } from '@reduxjs/toolkit'
import initialState from '../../initialStates/members'

export const memberSlice = createSlice({
	name: 'members',
	initialState,
	reducers: {},
})

export const getMembers = state => state.members

export default memberSlice.reducer
