import React, { useMemo } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { FixedHeader } from '../../components/common/fixedHeader'
import MobileMenu from '../../components/common/mobileMenu/component'
import './styles.scss'
import { useNavigate } from 'react-router-dom'
import { MainFooter } from '../../components/common/mainFooter'
import Navigation from '../../components/common/Navigation'

export const NotFound = () => {
	const { width } = useWindowSize()
	const navigate = useNavigate()
	const isMobile = useMemo(() => width < 768, [width])
	return (
		<>
			<Navigation />
			<main className="wrapper-not-found">
				<h2 className="not-found-title">Page not found</h2>
				<p>Please check the URL, for proper spelling and capitalization.</p>
				<button
					className="not-found-button hero-button"
					onClick={() => navigate('/')}
				>
					HOME PAGE
				</button>
			</main>
			<MainFooter />
		</>
	)
}
