import { createSlice } from '@reduxjs/toolkit'
import initialState from '../../initialStates/achivments'

export const achivmentsSlice = createSlice({
	name: 'achivments',
	initialState,
	reducers: {},
})

export const getAchivments = state => state.achivments

export default achivmentsSlice.reducer
