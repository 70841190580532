export const offers = [
	{
		id: '0',
		category: 'design',
		position: '2D Character Artist',
		location: 'Office/Remote',
		url: 'https://gen-tech.breezy.hr/p/cde4112997b5-2d-character-artist-holywater',
	},
	{
		id: '1',
		category: 'design',
		position: 'Art Lead for Mobile games',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/f4446af680f7-art-lead-for-mobile-games-holywater',
	},
	{
		id: '2',
		category: 'others',
		position: 'Business Analysts',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/d1b022897452-business-analysts-holywater',
	},
	{
		id: '3',
		category: 'others',
		position: 'Data Analyst',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/2c9688b00d96-data-analyst-holywater',
	},
	{
		id: '4',
		category: 'others',
		position: 'Data Scientist',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/b0b97295c5aa-data-scientist-holywater',
	},
	{
		id: '5',
		category: 'marketing',
		position: 'Facebook Media Buyer',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/5d4fb12e88f4-facebook-media-buyer-holywater',
	},
	{
		id: '6',
		category: 'engineering',
		position: 'Full stack (React+Node) developer',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/29a874c757ad-full-stack-react-node-developer-holywater',
	},
	{
		id: '7',
		category: 'design',
		position: 'Game Designer',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/a63fb56ae63e-game-designer-holywater',
	},
	{
		id: '8',
		category: 'design',
		position: 'Narrative designer',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/6e5b4e08f0e1-narrative-designer-holywater',
	},
	{
		id: '9',
		category: 'engineering',
		position: 'Node.js developer',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/4defb94abab7-node-js-developer-holywater',
	},
	{
		id: '10',
		category: 'operations',
		position: 'Operations Intern',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/1b9b748a0b7a-operations-intern-holywater',
	},
	{
		id: '11',
		category: 'marketing',
		position: 'PPC specialist',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/c197f41c9f19-ppc-specialist-holywater',
	},
	{
		id: '12',
		category: 'product',
		position: 'Product Owner',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/e90bfb1ca6db-product-owner-holywater',
	},
	{
		id: '13',
		category: 'product',
		position: 'Project Manager',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/e1a517ec869c-project-manager-holywater-game-dev',
	},
	{
		id: '14',
		category: 'design',
		position: 'UI/UX Designer',
		location: 'Office/Remote',
		url: 'https://gen-tech.breezy.hr/p/ad708ef75b40-ui-ux-designer-holywater',
	},
	{
		id: '15',
		category: 'marketing',
		position: 'User Acquisition Manager',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/70d3d5dcd933-user-acquisition-manager-holywater',
	},
	{
		id: '16',
		category: 'marketing',
		position: 'Creative Marketing Specialist',
		location: 'Remote',
		url: 'https://gen-tech.breezy.hr/p/1663f8117ad5-creative-marketing-specialist-holywater',
	},
	{
		id: '17',
		category: 'design',
		position: 'Graphic designer',
		location: 'Remote',
		url: 'https://gen-tech.breezy.hr/p/9c84d11f5346-remote-graphic-designer-holywater',
	},
	{
		id: '18',
		category: 'design',
		position: '2D Artist',
		location: 'Office/Remote',
		url: 'https://gen-tech.breezy.hr/p/a4333016ce76-2d-artist-holywater',
	},
	{
		id: '19',
		category: 'other',
		position: 'ASO/ASA specialist',
		location: 'Office/Remote',
		url: 'https://gen-tech.breezy.hr/p/73b3595a9fda-aso-asa-specialist-holywater',
	},
	{
		id: '20',
		category: 'engineering',
		position: 'Intern JS Developer',
		location: 'Office',
		url: 'https://gen-tech.breezy.hr/p/2576a1c42434-intern-js-developer-holywater',
	},
	{
		id: '21',
		category: 'marketing',
		position: 'Marketing creative producer',
		location: 'Remote',
		url: 'https://gen-tech.breezy.hr/p/5b3691a77222-remote-marketing-creative-producer-holywater',
	},
	{
		id: '22',
		category: 'marketing',
		position: 'Marketing creative screenwriter',
		location: 'Remote',
		url: 'https://gen-tech.breezy.hr/p/4caf9d5fc519-remote-marketing-creative-screenwriter-holywater',
	},
	{
		id: '23',
		category: 'design',
		position: 'Motion designer',
		location: 'Remote',
		url: 'https://gen-tech.breezy.hr/p/9049f481d4c4-remote-motion-designer-holywater',
	},
]

export default offers
