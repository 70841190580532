import { SectionAbout } from '../../components/mainPage/sectionAbout'
import { SectionMedia } from '../../components/mainPage/sectionMedia'
import { MainFooter } from '../../components/common/mainFooter'
import s from './styles.module.scss'
import SectionHero from '../../components/mainPage/sectionHero/component'
import SectionProduct from '../../components/mainPage/sectionProduct/component'
import SectionWork from '../../components/mainPage/sectionWork/component'
// import SocialSection from '../../components/mainPage/sectionSocial/component'
import { Helmet } from 'react-helmet'
import Navigation from '../../components/common/Navigation'
import { Link } from 'react-router-dom'
import { ArrowIcon } from '../../shared/icons/ArrowIcon'

const MainNewPage = () => {
	return (
		<div className={s.pageContainer}>
			<Helmet>
				<link rel="canonical" href="https://holywater.tech/" />
			</Helmet>

			<header className={s.pageHeader}>
        <Navigation />
				<div className={s.headerPromo}>
          <div className={s.container}>
            <div className={s.promoWrapper}>
              <p className={s.promoText}>
                We develop and publish books, audiobooks, interactive stories, and
                video series.
              </p>

              <div className={s.buttonsWrapper}>
                <Link to="/mydrama" className={s.heroButton}>
                  <span>My Drama<span><ArrowIcon /></span></span>
                </Link>
                {/* <Link to="/blog" className={s.heroButton}>
                  <span>Our blog <span><ArrowIcon /></span></span>
                </Link> */}
              </div>
            </div>

            <h1 className={s.promoHoly}>Holywater</h1>

            <h2 className={s.promoTitle}>
              <span className={s.firstLine}>
                <span>is</span>
                <span>a media-tech</span>
              </span>
              company that builds personalized worlds for 20+ million users by combining
              creators&apos; imagination with AI&apos;s efficiency.
            </h2>
          </div>
				</div>
			</header>

			<main className="page-main">
				<SectionHero />
				<SectionMedia />
				<SectionAbout />
				<SectionProduct />
				<SectionWork />
			</main>
			<MainFooter />
		</div>
	)
}

export { MainNewPage }
