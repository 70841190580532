import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const CrossIcon = createIcon({
	viewBox: '0 0 40 40',
	path: (
		<>
			<line x1="30.0518" y1="10.253" x2="10.2528" y2="30.052" stroke="white" />
			<line x1="29.3447" y1="30.0518" x2="9.5457" y2="10.2528" stroke="white" />
		</>
	),
})
