import React, { useMemo } from 'react'
import { Flex, Box, Text, Image, useBreakpointValue } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '../../../hooks/useWindowSize'

const TEAM = [
	{
		img: '/images/pishi/mob/vivat1.png',
		name: 'Юлія Орлова',
		position: (
			<>
				Генеральна директорка
				<br /> видавництва
			</>
		),
	},
	{
		img: '/images/pishi/mob/vivat2.png',
		name: 'Олена Рибка',
		position: (
			<>
				Шеф-редакторка
				<br />
				українського напрямку
			</>
		),
	},
	{
		img: '/images/pishi/mob/vivat3.png',
		name: 'Катерина Ільчук',
		position: 'Директорка із комунікацій',
	},
	{
		img: '/images/pishi/mob/vivat4.png',
		name: 'Артем Літвінець',
		position: 'Головний редактор',
	},
]
export const SectionVivat = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 834, [width])
	const isTab = useMemo(() => width < 1191, [width])
	return (
		<section
			style={{
				background: '#F3F3F4',
				padding: useBreakpointValue({
					sm: '120px 15px',
					md: '120px 0',
					lg: '120px 100px',
				}),
				maxWidth: useBreakpointValue({
					sm: '440px',
					md: '794px',
					lg: '1440px',
				}),
				margin: '0 auto',
			}}
		>
			<Box
				cursor="pointer"
				onClick={() => window.open('https://vivat.com.ua/', '_blank')}
			>
				<a className="anchor" id="vivat"></a>
				<Text variant="textTitle" textAlign="center" mb="42px">
					VIVAT
				</Text>
				<Flex
					flexDirection={isMobile ? 'column' : 'row'}
					justifyContent="center"
				>
					<Flex
						flexDirection={isMobile ? 'column' : 'row'}
						background="linear-gradient(180deg, #E9E9F2 0%, #E6E6F0 53.47%)"
						p={useBreakpointValue({
							sm: '44px 24px 0',
							md: '20px 10px 20px 20px ',
							lg: '75px 80px',
						})}
						width={isMobile ? '100%' : '50%'}
						height="100%"
						mb={isMobile ? '0' : '52px'}
					>
						<Box>
							<Text
								variant="pishiMedium"
								color="#03060D"
								fontSize={useBreakpointValue({
									sm: '20px',
									md: '20px',
									lg: '32px',
								})}
								mb={useBreakpointValue({
									sm: '20px',
									md: '12px',
									lg: '20px',
								})}
							>
								Vivat — видавництво, що спеціалізується на виданні книжкових
								бестселерів українською мовою та є одним з трьох найбільших
								видавництв країни.
							</Text>
							<Text
								variant="pishiRegular"
								color="#313133"
								fontSize="16px"
								mb="42px"
							>
								Серед книжок Vivat кожна і кожен може знайти книжку для себе:
								романтичні комедії і сімейні саги, психологічна проза і любовні
								історії, фантастика і фентезі, трилери і детективи, психологічні
								бестселери і нонфікшн про історію.
							</Text>
						</Box>
					</Flex>
					<Box mb="42px" width={isMobile ? '100%' : '50%'}>
						<Image
							src={
								isMobile
									? '/images/pishi/mob/vivat.png'
									: '/images/pishi/desc/vivat.png'
							}
							width="100%"
							display="block"
							objectFit="contain"
							borderBottomRadius="10px"
							mixBlendMode="luminosity"
						></Image>
					</Box>
				</Flex>
			</Box>
			<Text
				variant="textTitle"
				textAlign={isMobile ? 'center' : 'start'}
				mb="42px"
			>
				Команда
			</Text>
			{isTab ? (
				<Swiper
					slidesPerView="auto"
					speed={1500}
					freeMode={true}
					spaceBetween={14}
					loop
					// pagination={{ type: 'progressbar' }}
					className="about-slider"
					allowSlidePrev={true}
					allowSlideNext={true}
				>
					{TEAM.map(({ img, name, position }) => (
						<SwiperSlide key={img} style={{ width: 'auto' }}>
							<Image src={img} w="300px" h="292px" mb="20px"></Image>
							<Text
								variant="pishiRegular"
								color="#03060D"
								fontSize="24px"
								mb="10px"
							>
								{name}
							</Text>
							<Text
								variant="textBadge"
								border="1px solid #03060D"
								display="inline-block"
								mb="5px"
							>
								{position}
							</Text>
						</SwiperSlide>
					))}
				</Swiper>
			) : (
				<Flex gap="14px" m="auto" justifyContent="space-around">
					{TEAM.map(({ img, name, position }) => (
						<Box key={img}>
							<Image src={img} mb="20px"></Image>
							<Text
								variant="pishiRegular"
								color="#03060D"
								fontSize="24px"
								mb="10px"
							>
								{name}
							</Text>
							<Text
								variant="textBadge"
								border="1px solid #03060D"
								display="inline-block"
							>
								{position}
							</Text>
						</Box>
					))}
				</Flex>
			)}
		</section>
	)
}
