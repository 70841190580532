import s from './styles.module.scss'

const SectionAboutApp = () => {
  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <span className={s.label}>About app</span>
          <div className={s.contentWrapper}>
            <img 
              className={s.logo}
              src="/images/myDrama/my_drama_logo.webp"
              alt="My Drama app logo" 
            />

            <h2 className={s.heading}>What is My&nbsp;Drama?</h2>
            <p className={s.text}>
              Designed specifically for today&apos;s viewers who prefer content on the go, 
              the <span>My&nbsp;Drama app</span> offers an experience tailored to today&apos;s 
              mobile-first audience. In the <span>My&nbsp;Drama app,</span> each episode of 
              the series lasts 1–2 minutes, which ensures a 90% watch completion rate.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionAboutApp