export const textStyles = {
	baseStyle: {
		fontSize: '16px',
		lineHeight: '150%',
		color: '#fff',
		fontFamily: 'e-Ukraine',
	},
	variants: {
		active: {
			textDecoration: 'underline',
			fontWeight: 'Bold',
		},
		pishiRegular: {
			fontFamily: 'Inter-Regular',
			fontWeight: 400,
			fontSize: '14px',
		},
		pishiMedium: {
			fontFamily: 'Inter-Medium',
			fontWeight: 500,
			fontSize: '14px',
			lineHeight: '140%',
			letterSpacing: '-0.5px',
		},
		textBadge: {
			fontFamily: 'Inter-Regular',
			fontWeight: 400,
			fontSize: '18px',
			color: '#03060D',
			padding: '6px 12px 8px',
			borderRadius: '6px',
			lineHeight: '140%',
			letterSpacing: '-0.5px',
		},
		textBadgeAccent: {
			fontFamily: 'Inter-Regular',
			fontWeight: 400,
			fontSize: '18px',
			color: '#fff',
			padding: '6px 12px 8px',
			borderRadius: '10px',
			lineHeight: '140%',
			letterSpacing: '-0.5px',
			background: '#D04B6E',
			border: '1px solid #fff',
		},
		textTitle: {
			fontFamily: 'Inter-Medium',
			fontWeight: 500,
			fontSize: '40px',
			lineHeight: '120%',
			letterSpacing: '-0.5px',
			color: '#03060D',
		},
		default: {},
	},
	defaultProps: {
		variant: 'default',
	},
}
