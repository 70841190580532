import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const UsersIcon = createIcon({
	viewBox: '0 0 42 40',
	path: (
		<>
			<path
				d="M29.2295 17.7424C32.9691 17.7424 36.0003 14.7112 36.0003 10.9716C36.0003 7.23401 32.9691 4.20081 29.2295 4.20081"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M32.3428 25.1006C33.4599 25.1766 34.5689 25.3368 35.6635 25.5791C37.1811 25.881 39.0088 26.5032 39.6578 27.8648C40.0726 28.7376 40.0726 29.7541 39.6578 30.6269C39.0109 31.9884 37.1811 32.6107 35.6635 32.9228"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.0274 26.5585C23.6052 26.5585 30.0782 27.7064 30.0782 32.2922C30.0782 36.88 23.6463 38.067 16.0274 38.067C8.44948 38.067 1.97852 36.9211 1.97852 32.3333C1.97852 27.7455 8.40841 26.5585 16.0274 26.5585Z"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.0275 20.0125C11.029 20.0125 7.02441 16.0059 7.02441 11.0074C7.02441 6.01089 11.029 2.00427 16.0275 2.00427C21.026 2.00427 25.0326 6.01089 25.0326 11.0074C25.0326 16.0059 21.026 20.0125 16.0275 20.0125Z"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</>
	),
})
