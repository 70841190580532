const holyValues = [
	{
		id: 0,
		value: 'Constant growth',
		img: '/images/common/zapovedi/growth.png',
		description: 'Seek Growth. Seek its strength and presence continually!',
		source: 'Psalm 105:4',
	},
	{
		id: 1,
		value: 'Self-motivation',
		img: '/images/common/zapovedi/motivation.png',
		description: `If you have faith, you can say to this mountain, ‘Move from here to there,’ and it will move. Nothing will be impossible for you.`,
		source: 'Matthew 17:20',
	},
	{
		id: 2,
		value: 'Persistence & flexibility',
		img: '/images/common/zapovedi/persistence.png',
		description:
			'Be strong and do not give up, for your work will be rewarded.',
		source: '2 Chronicles 15:7',
	},
	{
		id: 3,
		value: 'Self-awareness',
		img: '/images/common/zapovedi/awareness.png',
		description:
			"The purpose in a man's heart is like deep water, but a man of understanding will draw it out.",
		source: 'Proverbs 20:5',
	},
	{
		id: 4,
		value: 'Freedom and responsibility',
		img: '/images/common/zapovedi/freedom.png',
		description: `I have the right to do anything for whatever one sows, that will he also reap.`,
		source: 'Galatians 6:7-9',
	},
	{
		id: 5,
		value: 'Result orientation',
		img: '/images/common/zapovedi/result.png',
		description: `Commit to the Lord [Result] whatever you do, and he will establish your plans.`,
		source: 'Proverbs 16:3',
	},
]

export default holyValues
