export const popoverStyles = {
	baseStyle: {
		header: {
			padding: '0 0 10px',
			borderBottomWidth: '0',
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			bg: 'transparent',
			textOverflow: 'ellipsis',
		},
		popper: {
			px: '18px',
			py: '16px',
			background: '#191818',
			border: '1px solid #323232',
			borderRadius: 20,
		},
		body: {
			padding: '0',
		},
		content: {
			w: '189px',
			_focus: {
				outline: 'none',
				boxShadow: 'none',
				bg: 'transparent',
				border: 'none',
			},
			bg: 'transparent',
		},
	},
}
