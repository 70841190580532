import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const PhoneIcon = createIcon({
	viewBox: '0 0 24 25',
	path: (
		<>
			<path
				// fill-rule="evenodd"
				// clip-rule="evenodd"
				d="M18 3.5V21.5C18 22.0523 17.5523 22.5 17 22.5H7C6.44772 22.5 6 22.0523 6 21.5V3.5C6 2.94772 6.44772 2.5 7 2.5H17C17.5523 2.5 18 2.94772 18 3.5V3.5Z"
				stroke="white"
				// stroke-linecap="round"
				// stroke-linejoin="round"
			/>
			<rect
				x="14"
				y="2.5"
				width="1"
				height="4"
				transform="rotate(90 14 2.5)"
				stroke="white"
				// stroke-linecap="round"
				// stroke-linejoin="round"
			/>
		</>
	),
})
