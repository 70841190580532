import { Helmet } from 'react-helmet'
import s from './styles.module.scss'
import SectionHero from '../../components/myDramaPage/sectionHero'
import SectionQuote from '../../components/myDramaPage/sectionQuote'
import SectionMyDrama from '../../components/myDramaPage/sectionMyDrama'
import SectionAboutApp from '../../components/myDramaPage/sectionAboutApp'
import SectionOutcomes from '../../components/myDramaPage/sectionOutcomes'
import SectionFeatures from '../../components/myDramaPage/sectionFeatures'
import SectionTestimonials from '../../components/myDramaPage/sectionTestimonials'
import SectionPartner from '../../components/myDramaPage/sectionPartner'
import SectionFAQ from '../../components/myDramaPage/sectionFAQ'
import Navigation from '../../components/common/Navigation'
import { MainFooter } from '../../components/common/mainFooter'

const MyDramaPage = () => {
  return (
    <div className={s.pageContainer}>
      <Helmet>
        <title>Vertical Video Series Platform: My Drama App by HOLYWATER</title>
        <meta name="description" content="Discover My Drama by HOLYWATER! 🎥📚 Enjoy short, captivating vertical videos inspired by our top books and interactive stories. Perfect for quick entertainment!" />
        <link rel="canonical" href="https://holywater.tech/mydrama" />
      </Helmet>

      <header>
        <Navigation />
      </header>

      <main>
        <SectionHero />
        <SectionQuote />
        <SectionMyDrama />
        <SectionAboutApp />
        <SectionOutcomes />
        <SectionFeatures />
        <SectionTestimonials />
        <SectionPartner />
        <SectionFAQ />
      </main>

      <MainFooter buttonColor="#4743FF" />
    </div>
  )
}

export default MyDramaPage