import {
	Flex,
	Image,
	Text,
	useDisclosure,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	IconButton,
	Link,
	List,
	ListItem,
	Box,
	useBreakpointValue,
} from '@chakra-ui/react'
import React, { useMemo, useState, useEffect } from 'react'
import { CrossIcon } from '../../../shared/icons/CrossIcon'
import { HamburgerIcon } from '../../../shared/icons/HamburgerIcon'
import { InstaIcon } from '../../../shared/icons/InstaIcon'
import { Link as LinkS, animateScroll as scroll } from 'react-scroll'
import { useLocation, useNavigate } from 'react-router-dom'
import useWindowSize from '../../../hooks/useWindowSize'

const Header = () => {
	const [viewText, setViewText] = React.useState(true)
	const [showComponent, setShowComponent] = useState(true)
	const [lastScrollTop, setLastScrollTop] = useState(0)

	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 834, [width])
	const isTab = useMemo(() => width < 1191, [width])
	const { isOpen, onOpen, onClose } = useDisclosure()
	const handleLinkClick = section => {
		if (pathname !== '/pyshy') {
			navigate('/pyshy')
			setTimeout(() => {
				const scrollY = document
					.getElementById(section)
					.getBoundingClientRect().top
				scroll.scrollTo(scrollY)
			}, 300)
		} else {
			scroll.scrollTo(section)
		}
	}
	const handleScroll = () => {
		const currentScrollTop = window.scrollY
		if (currentScrollTop > lastScrollTop) {
			// Скролиться вниз
			setShowComponent(false)
		} else {
			// Скролиться вверх
			setShowComponent(true)
		}
		setLastScrollTop(currentScrollTop)
	}

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [lastScrollTop])

	const padding = showComponent ? '20px 80px' : '20px 80px'
	const mobTranslate = viewText ? 'translateY(60%)' : 'translateY(0)'
	const deskTranslate = viewText ? ' translateY(30%)' : 'translateY(0)'
	const translate = isMobile ? mobTranslate : deskTranslate
	return (
		<Box background="#03060D">
			<>
				{isTab ? (
					<>
						{viewText && (
							<Flex
								gap="24px"
								position="fixed"
								top="0"
								left="0"
								zIndex="500"
								width="100%"
								background="#03060D"
								p="0 15px"
							>
								<Flex
									justifyContent="space-between"
									width="100%"
									maxW="1440px"
									m="0 auto"
								>
									<Text variant="pishiRegular">
										<Link
											style={{ textDecoration: 'underline' }}
											to="form"
											as={LinkS}
											spy={true}
											smooth={true}
											duration={1000}
											onClick={() => {
												onClose()
												// handleLinkClick('form')
											}}
										>
											Надсилай
										</Link>{' '}
										свій текст у жанрі romance до 31 серпня, або створи новий до
										кінця вересня та отримай контракт на видавництво книг
									</Text>
									<CrossIcon
										w="28px"
										h="28px"
										onClick={() => setViewText(false)}
									/>
								</Flex>
							</Flex>
						)}
					</>
				) : (
					<>
						{' '}
						{viewText && (
							<Flex
								gap="24px"
								justifyContent="space-between"
								position="fixed"
								top="0"
								left="0"
								zIndex="500"
								width="100%"
								background="#03060D"
								// trasform="translateX(-50%)"
								// maxW="1440px"
							>
								<Flex
									justifyContent="space-between"
									width="100%"
									// maxW="1440px"
									m="0 auto"
									p="0 32px"
								>
									<Text variant="pishiRegular">
										<Link
											style={{ textDecoration: 'underline' }}
											to="form"
											as={LinkS}
											spy={true}
											smooth={true}
											duration={1000}
											onClick={() => {
												onClose()
												// handleLinkClick('form')
											}}
										>
											Надсилай
										</Link>{' '}
										свій текст у жанрі romance до 31 серпня, або створи новий до
										кінця вересня та отримай контракт на видавництво книг
									</Text>
									<CrossIcon
										w="28px"
										h="28px"
										onClick={() => setViewText(false)}
									/>
								</Flex>
							</Flex>
						)}
					</>
				)}
			</>

			<header
				style={{
					padding: useBreakpointValue({
						sm: '10px 13px 10px 20px',
						md: padding,
						lg: padding,
					}),
					position: 'fixed',
					zIndex: '100',
					width: '100%',
					background: '#03060D',
					top: 0,
					transform: showComponent ? translate : 'scaleY(1) translateY(-130%)',
					transition: 'transform 1s',
				}}
			>
				{isTab ? (
					<>
						{/* {showComponent && ( */}
						<Flex alignItems="center">
							<Image
								src="/images/pishi/mob/pishi.png"
								onClick={handleLinkClick}
							></Image>
							<Button
								onClick={onOpen}
								as={IconButton}
								// variant="outline"
								ml="auto"
								p="0"
								display="flex"
								alignItems="center"
								name="mobile menu"
								icon={<HamburgerIcon w="35px" h="30px" />}
								background="transparent"
								fill="#fff"
								_hover={{ background: 'transparent' }}
							/>
							<Drawer onClose={onClose} isOpen={isOpen} size="sm">
								<DrawerOverlay />
								<DrawerContent>
									<Flex
										justifyContent="spaceBetween"
										alignItems="center"
										background="black"
										padding="20px"
									>
										<Image src="/images/pishi/mob/pishi.png"></Image>
										<DrawerCloseButton
											w="28px"
											h="28px"
											top="20px"
											right="20px"
										/>
									</Flex>
									<DrawerBody background="#010101" pt="30px">
										<List
											textAlign="center"
											display="flex"
											flexDirection="column"
											gap="24px"
										>
											<ListItem>
												<Link
													display={{ md: 'block' }}
													as={LinkS}
													to="project"
													spy={true}
													smooth={true}
													duration={1000}
													fs="14px"
													color="#fff"
													offset={-50}
													textTransform="uppercase"
													onClick={() => {
														onClose()
														// handleLinkClick('project')
													}}
												>
													ПРОЄКТ
												</Link>
											</ListItem>
											<ListItem>
												<Link
													display={{ md: 'block' }}
													as={LinkS}
													to="stage"
													spy={true}
													smooth={true}
													duration={1000}
													offset={-50}
													textTransform="uppercase"
													color="#fff"
													onClick={() => {
														onClose()
														// handleLinkClick('stage')
													}}
												>
													ЕТАПИ
												</Link>
											</ListItem>
											<ListItem>
												<Link
													display={{ md: 'block' }}
													as={LinkS}
													to="holywater"
													spy={true}
													smooth={true}
													duration={1000}
													offset={-50}
													textTransform="uppercase"
													color="#fff"
													onClick={() => {
														onClose()
														// handleLinkClick('holywater')
													}}
												>
													HOLYWATER
												</Link>
											</ListItem>
											<ListItem>
												<Link
													display={{ md: 'block' }}
													as={LinkS}
													to="vivat"
													spy={true}
													smooth={true}
													offset={-50}
													duration={1000}
													textTransform="uppercase"
													color="#fff"
													onClick={() => {
														onClose()
														// handleLinkClick('vivat')
													}}
												>
													VIVAT
												</Link>
											</ListItem>
											<ListItem>
												<Link
													display={{ md: 'block' }}
													as={LinkS}
													to="faq"
													spy={true}
													smooth={true}
													offset={-50}
													duration={1000}
													textTransform="uppercase"
													color="#fff"
													onClick={() => {
														onClose()
														// handleLinkClick('faq')
													}}
												>
													FAQ
												</Link>
											</ListItem>
											<ListItem>
												<Link
													display={{ md: 'block' }}
													as={LinkS}
													to="https://www.instagram.com/pyshy.ua/"
													target="_blank"
													spy={true}
													smooth={true}
													color="#fff"
													duration={1000}
													onClick={() => {
														onClose()
														window.open(
															'https://instagram.com/pyshy.ua/',
															'_blank',
														)
													}}
												>
													<InstaIcon />
												</Link>
											</ListItem>
											<ListItem>
												<Link
													as={LinkS}
													p="12px 80px"
													to="form"
													spy={true}
													offset={-50}
													smooth={true}
													borderRadius="10px"
													border="1px solid #fff"
													onClick={() => {
														onClose()
														// handleLinkClick('form')
													}}
												>
													Надіслати свій текст
												</Link>
											</ListItem>
										</List>
									</DrawerBody>
								</DrawerContent>
							</Drawer>
						</Flex>
						{/* )} */}
					</>
				) : (
					<Box position="relative" width="100%">
						{/* {showComponent && ( */}
						<nav>
							<Flex
								justifyContent="space-between"
								alignItems="center"
								width="100%"
							>
								<List
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									// width="100%"
									gap="50px"
								>
									<ListItem>
										<Link
											color="#fff"
											display={{ base: 'none', md: 'block' }}
											as={LinkS}
											to="project"
											spy={true}
											smooth={true}
											offset={-100}
											duration={1000}
											// onClick={() => handleLinkClick('project')}
										>
											ПРОЄКТ
										</Link>
									</ListItem>
									<ListItem>
										<Link
											color="#fff"
											display={{ base: 'none', md: 'block' }}
											as={LinkS}
											to="stage"
											spy={true}
											smooth={true}
											offset={-100}
											duration={1000}
											// onClick={() => handleLinkClick('stage')}
										>
											ЕТАПИ
										</Link>
									</ListItem>
									<ListItem>
										<Link
											display={{ base: 'none', md: 'block' }}
											as={LinkS}
											to="holywater"
											color="#fff"
											spy={true}
											smooth={true}
											offset={-100}
											duration={1000}
											// onClick={() => handleLinkClick('holywater')}
										>
											HOLYWATER
										</Link>
									</ListItem>
									<ListItem>
										<Link
											display={{ base: 'none', md: 'block' }}
											as={LinkS}
											to="vivat"
											color="#fff"
											spy={true}
											smooth={true}
											offset={-100}
											duration={1000}
											// onClick={() => handleLinkClick('vivat')}
										>
											VIVAT
										</Link>
									</ListItem>
								</List>

								<Image src="/images/pishi/mob/pishi.png"></Image>

								<List
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									// width="10%"
									gap="50px"
								>
									<ListItem>
										<Link
											display={{ base: 'none', md: 'block' }}
											as={LinkS}
											to="faq"
											spy={true}
											color="#fff"
											smooth={true}
											offset={-100}
											duration={1000}
											// onClick={() => handleLinkClick('faq')}
										>
											FAQ
										</Link>
									</ListItem>
									<ListItem>
										<Link
											display={{ base: 'none', md: 'block' }}
											onClick={() =>
												window.open(
													'https://www.instagram.com/pyshy.ua/',
													'_blank',
												)
											}
										>
											<InstaIcon w="24px" h="24px" />
										</Link>
									</ListItem>
									<Link
										as={LinkS}
										p="12px 80px"
										borderRadius="10px"
										to="form"
										color="#fff"
										border="1px solid #fff"
									>
										Надіслати свій текст
									</Link>
								</List>
							</Flex>
						</nav>
						{/* )} */}
					</Box>
				)}
			</header>
		</Box>
	)
}

export default Header
