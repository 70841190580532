import s from './styles.module.scss'
import { useState } from 'react'
import cn from 'classnames'
// import { ChevronIcon } from '../../../shared/icons/ChevronIcon'
import { ReactComponent as ChevronIcon } from './faq_chevron.svg'

const FAQ = [
  {
    id: 1,
    question: 'What types of video series are available on My Drama?',
    answer: (
      <>
        My Drama offers diverse video series, including romance, thriller, fantasy, and more. 
        Each series is designed to captivate with immersive storytelling and high-quality production.
      </>
    ),
  },
  {
    id: 2,
    question: 'How often is new content added to My Drama?',
    answer: (
      <>
        New content is added regularly to <span>My Drama app</span>, with fresh series and episodes 
        released weekly, ensuring there&apos;s always something new for viewers to enjoy.
      </>
    ),
  },
  {
    id: 3,
    question: 'Is My Drama content available in multiple languages?',
    answer: (
      <>
        Yes, My Drama offers content in multiple languages and provides subtitles for most series. 
        We&apos;re committed to making our platform accessible to a global audience and continually 
        expanding our language options.
      </>
    ),
  },
  {
    id: 4,
    question: 'How does My Drama use AI to enhance the viewing experience?',
    answer: (
      <>
        <span>My Drama app</span> utilizes AI to personalize content recommendations and optimize streaming quality. 
        AI helps ensure that each viewer&apos;s experience is tailored, smooth, and engaging, making it 
        easier to discover new series that match your preferences and to interact seamlessly with the content.
      </>
    ),
  },
  {
    id: 5,
    question: 'How can I become a content creator for My Drama?',
    answer: (
      <>
        To become a content creator for My Drama <span>series platform</span>, please contact us via our 
        email: <a href="mailto:info@holywater.tech">info@holywater.tech</a>. We welcome innovative creators to 
        collaborate and bring their unique stories to our platform.
      </>
    ),
  },
];

const SectionFAQ = () => {
  const [openQuestion, setOpenQuestion] = useState(1);

  const toggleQuestion = (id) => {
    setOpenQuestion(prevState => (prevState === id ? null : id));
  };

  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.wrapper}>
          <span className={s.label}>FAQ</span>

          <div className={s.contentBlock}>
            <h2 className={s.heading}>FAQ</h2>

            {FAQ.map(({ id, question, answer }) => (
              <div
                key={id}
                className={s.questionBlock}
                role='button'
                tabIndex='0'
                onClick={() => toggleQuestion(id)}
                onKeyDown={(e) => e.key === 'Enter' && toggleQuestion(id)}
              >
                <div className={s.questionHeading}>
                  <h3 
                    className={s.question} 
                    aria-controls={`answer-${id}`} 
                    aria-expanded={openQuestion === id}
                  >
                    {question}
                  </h3>
                  <span className={cn(s.chevronIcon, { [s.open]: openQuestion === id })}><ChevronIcon /></span>
                </div>

                <div 
                  className={cn(s.answerWrapper, { [s.open]: openQuestion === id })} 
                  id={`answer-${id}`} 
                  aria-hidden={openQuestion !== id}
                >
                  <div>
                    <p className={s.answer}>{answer}</p>
                  </div>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionFAQ