import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const LangIcon = createIcon({
	viewBox: '0 0 24 25',
	path: (
		<>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 22.5C17.5228 22.5 22 18.0228 22 12.5C22 6.97715 17.5228 2.5 12 2.5C6.47715 2.5 2 6.97715 2 12.5C2 18.0228 6.47715 22.5 12 22.5Z"
				stroke="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 21.5C12 21.5 16 18.2273 16 12.5C16 6.77273 12 3.5 12 3.5C12 3.5 8 6.77273 8 12.5C8 18.2273 12 21.5 12 21.5Z"
				stroke="white"
			/>
			<path d="M2.5 9.5H21.5" stroke="white" strokeLinecap="round" />
			<path d="M2.5 15.5H21.5" stroke="white" strokeLinecap="round" />
		</>
	),
})
