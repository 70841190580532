import React, { useMemo } from 'react'
import { Flex, Box, Text, Image, useBreakpointValue } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import useWindowSize from '../../../hooks/useWindowSize'

const TEAM = [
	{
		img: '/images/pishi/mob/team1.png',
		name: 'Богдан Несвіт',
		position: 'CEO & Founder',
		link: 'https://www.linkedin.com/in/bogdannesvit/',
	},
	{
		img: '/images/pishi/mob/team2.png',
		name: 'Анатолій Касʼянов',
		position: 'CTO & Co-Founder',
		link: 'https://www.linkedin.com/in/anatolii-kasianov/',
	},
	{
		img: '/images/pishi/mob/team3.png',
		name: 'Юлія Смирнова',
		position: 'Head of Production',
		link: 'https://www.linkedin.com/in/julia-smirnova-2b8475104/',
	},
	{
		img: '/images/pishi/mob/team4.png',
		name: 'Христина Цапко',
		position: 'Head of Creative Marketing',
		link: 'https://www.linkedin.com/in/kristina-t-9989aa1a3/',
	},
]
export const SectionHW = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 834, [width])
	const isTab = useMemo(() => width < 1191, [width])
	return (
		<section
			style={{
				background: '#F3F3F4',
				padding: useBreakpointValue({
					sm: '0 15px',
					md: '0',
					lg: '0 100px',
				}),
				maxWidth: useBreakpointValue({
					sm: '440px',
					md: '794px',
					lg: '1440px',
				}),
				margin: '0 auto',
			}}
		>
			<Box
				mb={useBreakpointValue({
					sm: '0',
					md: '42px',
					lg: '52px',
				})}
				cursor="pointer"
				onClick={() => window.open('https://holywater.tech/', '_blank')}
			>
				<a className="anchor" id="holywater"></a>
				<Text variant="textTitle" textAlign="center" mb="42px">
					HOLYWATER
				</Text>
				<Flex
					flexDirection={isMobile ? 'column' : 'row'}
					justifyContent="center"
				>
					<Flex
						flexDirection="column"
						background="linear-gradient(180deg, #F7EAED 0%, #F4DFE4 52.22%)"
						borderRadius="10px"
						p={useBreakpointValue({
							sm: '44px 24px 0',
							md: '20px 10px 20px 20px ',
							lg: '45px 35px',
						})}
						width={isMobile ? '100%' : '50%'}
						height="auto"
					>
						<Box>
							<Text
								variant="pishiMedium"
								color="#03060D"
								fontSize={useBreakpointValue({
									sm: '20px',
									md: '20px',
									lg: '32px',
								})}
								mb={useBreakpointValue({
									sm: '20px',
									md: '12px',
									lg: '20px',
								})}
							>
								HOLYWATER – це технологічна медіакомпанія, що створює
								персоналізовані світи для 20+ мільйонів користувачів
							</Text>
							<Text
								variant="pishiRegular"
								color="#313133"
								fontSize="16px"
								mb="42px"
							>
								Ми створюємо та публікуємо книги, аудіокниги, інтерактивні
								історії та відеосеріали. Наша місія – розкривати потенціал людей
								і давати можливість творцям контенту надихати мільйони людей по
								всьому світу, зосереджуючись на тому, що вони роблять найкраще.
							</Text>
						</Box>
					</Flex>
					<Box mb={isMobile ? '42px' : 0} width={isMobile ? '100%' : '50%'}>
						<Image
							w="100%"
							src={
								isMobile
									? '/images/pishi/mob/holywater.png'
									: '/images/pishi/desc/holywater.png'
							}
							// display="block"
							objectFit="contain"
							borderBottomRadius="10px"
						></Image>
					</Box>
				</Flex>
			</Box>
			<Text
				variant="textTitle"
				textAlign={isMobile ? 'center' : 'start'}
				mb="42px"
			>
				Команда
			</Text>
			{isTab ? (
				<Swiper
					slidesPerView="auto"
					speed={1500}
					freeMode={true}
					spaceBetween={14}
					loop
					// pagination={{ type: 'progressbar' }}
					className="about-slider"
					allowSlidePrev={true}
					allowSlideNext={true}
				>
					{TEAM.map(({ img, name, position, link }) => (
						<SwiperSlide
							key={img}
							style={{ width: 'auto' }}
							onClick={() => window.open(link, '_blank')}
						>
							<Image src={img} w="300px" h="292px" mb="20px"></Image>
							<Text
								variant="pishiRegular"
								color="#03060D"
								fontSize="24px"
								mb="10px"
							>
								{name}
							</Text>
							<Text
								variant="textBadge"
								border="1px solid #03060D"
								display="inline-block"
							>
								{position}
							</Text>
						</SwiperSlide>
					))}
				</Swiper>
			) : (
				<Flex gap="14px" m="auto" justifyContent="space-around">
					{TEAM.map(({ img, name, position, link }) => (
						<Box
							key={img}
							cursor="pointer"
							onClick={() => window.open(link, '_blank')}
						>
							<Image src={img} mb="20px"></Image>
							<Text
								variant="pishiRegular"
								color="#03060D"
								fontSize="24px"
								mb="10px"
							>
								{name}
							</Text>
							<Text
								variant="textBadge"
								border="1px solid #03060D"
								display="inline-block"
							>
								{position}
							</Text>
						</Box>
					))}
				</Flex>
			)}
		</section>
	)
}
