import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const HamburgerIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path d="M28,10H4A1,1,0,0,1,4,8H28a1,1,0,0,1,0,2Z" />
			<path d="M28,17H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
			<path d="M28,24H4a1,1,0,0,1,0-2H28a1,1,0,0,1,0,2Z" />
		</>
	),
})
