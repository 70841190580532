import { configureStore } from '@reduxjs/toolkit'

import achivmentsReducer from './slices/achivmentsSlice'
import holyValuesReducer from './slices/holyValuesSlice'
import membersReducer from './slices/membersSlice'
import offersReducer from './slices/offersSlice'
import productsreducer from './slices/productsSlice'
import nftsReducer, { STATE_KEY as NFT_STATE_KEY } from './slices/nfts'

export const store = configureStore({
	reducer: {
		achivments: achivmentsReducer,
		holyValues: holyValuesReducer,
		members: membersReducer,
		offers: offersReducer,
		products: productsreducer,
		[NFT_STATE_KEY]: nftsReducer,
	},
})
