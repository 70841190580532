export const headingStyles = {
	baseStyle: {
		fontFamily: 'e-Ukraine-Head-Regular',
		fontWeight: 'normal',
		fontSize: '40px',
		color: '#fff',
	},
	sizes: {
		sm: { fontSize: '40px' },
		md: { fontSize: '40px' },
		lg: {
			fontSize: '100px',
			lineHeight: '120px',
		},
		defaultProps: {
			size: {
				size: 'sm',
			},
		},
	},
}
