import { Link, Image } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Link as LinkS, animateScroll as scroll } from 'react-scroll'
import useWindowSize from '../../../hooks/useWindowSize'
import './styles.scss'

const FixedHeader = () => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])

	const [scrollNav, setScrollNav] = useState(false)

	const handleClickLogo = () => {
		// if (pathname === '/') {
		// 	scroll.scrollToTop()
		// 	return
		// }
		return navigate('/pyshy')
	}

	const changeNav = useCallback(() => {
		if (window.scrollY >= 80) {
			setScrollNav(true)
			return
		}

		setScrollNav(false)
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', changeNav)

		return () => {
			window.removeEventListener('scroll', changeNav)
		}
	}, [])
	const handleLinkClick = section => {
		if (pathname !== '/') {
			const scrollY = document
				.getElementById(section)
				.getBoundingClientRect().top
			navigate('/')
			setTimeout(() => {
				scroll.scrollTo(scrollY - 100)
			}, 300)
		} else {
			scroll.scrollTo(section)
		}
	}
	return (
		<div className="header-fixed">
			<nav className={`header-nav ${scrollNav && isMobile ? '' : ''}`}>
				<div
					className={`header-logo ${scrollNav && isMobile ? '' : ''}`}
					// onClick={handleClickLogo}
				>
					{/* <img src="/images/mainPage/logo2.png" onClick={handleClickLogo} /> */}
					<Image
						onClick={handleLinkClick}
						src="/images/mainPage/Hw2.svg"
						cursor="pointer"
						w="auto"
						h="100px"
						mr={4}
					></Image>
					{/* <Image
						onClick={handleClickLogo}
						src="/images/pishi/mob/pishi.png"
						cursor="pointer"
						w="auto"
						h="30px"
					></Image> */}
				</div>

				<ul className="header-menu">
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="about"
							spy={true}
							smooth={true}
							offset={-100}
							duration={1000}
							onClick={() => handleLinkClick('about')}
						>
							ABOUT US
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="products"
							spy={true}
							smooth={true}
							offset={-100}
							duration={1000}
							onClick={() => handleLinkClick('products')}
						>
							OUR PRODUCTS
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="work"
							spy={true}
							smooth={true}
							offset={-100}
							duration={1000}
							onClick={() => handleLinkClick('work')}
						>
							WORK WITH US
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="social"
							spy={true}
							offset={-100}
							smooth={true}
							duration={1000}
							onClick={() => handleLinkClick('social')}
						>
							SOCIAL IMPACT & FEATURES
						</Link>
					</li>
					<li>
						<Link
							display={{ base: 'none', md: 'block' }}
							as={LinkS}
							to="contact"
							spy={true}
							smooth={true}
							offset={-200}
							duration={1000}
							onClick={() => handleLinkClick('contact')}
						>
							CONTACT
						</Link>
					</li>
				</ul>
			</nav>
		</div>
	)
}

export { FixedHeader }
