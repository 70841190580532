import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const HeartIcon = createIcon({
	viewBox: '0 0 43 40',
	path: (
		<>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.4825 38.1562C17.0249 35.4129 12.878 32.1841 9.11615 28.5276C6.47138 25.8944 4.45795 22.6843 3.23007 19.143C1.02047 12.2735 3.60143 4.40918 10.8244 2.08179C14.6205 0.85971 18.7665 1.55818 21.9653 3.95871C25.1653 1.56111 29.3098 0.862878 33.1061 2.08179C40.3291 4.40918 42.9286 12.2735 40.719 19.143C39.4911 22.6843 37.4777 25.8944 34.8329 28.5276C31.0711 32.1841 26.9242 35.4129 22.4666 38.1562L21.9838 38.4566L21.4825 38.1562Z"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M29.6523 9.8147C31.8401 10.5135 33.3945 12.4776 33.5888 14.7885"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</>
	),
})
