import s from './styles.module.scss'

const SectionQuote = () => {
  return (
    <section className={s.section}>
      <div className={s.container}>
        <img 
          className={s.authorImage}
          src="/images/myDrama/bogdan.webp"
          alt="Bogdan Nesvit, CEO and Founder of HOLYWATER" 
        />

        <p className={s.quote}>
          <span className={s.quoteMark}>
            <img className={s.markLeft} src="/images/myDrama/quote_mark.webp" alt="quote mark" /> W
          </span> 
          <span>e are challenging traditional streaming, Netflix, MAX, Amazon Prime, Apple TV+. We are competing with them in the same market and for the attention of viewer</span>
          
          <span className={s.quoteMark}>
            s <img className={s.markRight} src="/images/myDrama/quote_mark.webp" alt="quote mark" />
          </span>
        </p>
        <p className={s.author}>Bogdan Nesvit, CEO and Founder of HOLYWATER</p>
      </div>
    </section>
  )
}

export default SectionQuote