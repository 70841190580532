import s from './styles.module.scss'
import { useRef } from 'react';
import { useScroll, useTransform, motion } from 'framer-motion';

const SectionHero = () => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start start', 'end start'],
  });

  const sm = useTransform(scrollYProgress, [0, 1], [0, -50]);
  const md = useTransform(scrollYProgress, [0, 1], [0, -75]);
  const lg = useTransform(scrollYProgress, [0, 1], [0, -100]);

  const images = [
    {
      src: "/images/myDrama/hero_1.webp",
      alt: "The shy beauty and the billionaire beast",
      parallax: 0
    },
    {
      src: "/images/myDrama/hero_2.webp",
      alt: "Alpha king's hated princess",
      parallax: sm
    },
    {
      src: "/images/myDrama/hero_3.webp",
      alt: "The alpha's mate who cried wolf",
      parallax: md
    },
    {
      src: "/images/myDrama/hero_4.webp",
      alt: "Mate, mine",
      parallax: lg
    }
  ];

  return (
    <section ref={containerRef} className={s.section}>
      <div className={s.wrapper}>
        {images.map(image => (
          <motion.img
            key={image.alt}
            className={s.heroMovieImage}
            src={image.src}
            alt={image.alt}
            style={{ y: image.parallax }}
            whileHover={{ scale: 1.05 }}
          />
        ))}
        <img
          className={s.heroAppImage}
          src="/images/myDrama/hero_app.webp"
          alt="My Drama app"
        />
        <div className={s.headingContainer}>
          <div className={s.glow}></div>
          <h1 className={s.heading}>
            <span>Video</span> Series <br /> Platform
          </h1>
        </div>
      </div>
    </section>
  )
}

export default SectionHero