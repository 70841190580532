import { createSelector, createSlice } from '@reduxjs/toolkit'

export const STATE_KEY = 'nfts'

export const nftsSlice = createSlice({
	name: STATE_KEY,
	initialState: {
		ethers: 0,
		exchangeEthersRate: 0,
	},
	reducers: {
		setEthersCount: (state, action) => {
			state.ethers = action.payload
		},
		setExchangeEthersRate: (state, action) => {
			state.exchangeEthersRate = action.payload
		},
	},
})

export const { setEthersCount, setExchangeEthersRate } = nftsSlice.actions

const selectNftState = state => state.nfts

export const getEthersCount = createSelector(
	selectNftState,
	state => state.ethers,
)
export const getExchangeEthersRate = createSelector(
	selectNftState,
	state => state.exchangeEthersRate,
)

export default nftsSlice.reducer
