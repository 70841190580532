import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const StarIcon = createIcon({
	viewBox: '0 0 22 20',
	path: (
		<>
			<path
				d="M11 0L13.4697 7.60081H21.4616L14.996 12.2984L17.4656 19.8992L11 15.2016L4.53436 19.8992L7.00402 12.2984L0.538379 7.60081H8.53035L11 0Z"
				fill="white"
			/>
		</>
	),
})
