import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const LinkIcon = createIcon({
	viewBox: '0 0 24 24',
	path: (
		<>
			<path
				d="M9 7C9 7 7 7 7 7C4.23858 7 2 9.23858 2 12C2 14.7614 4.23858 17 7 17C7 17 9 17 9 17"
				stroke="white"
				strokeLinecap="round"
			/>
			<path d="M8 12H16" stroke="white" strokeLinecap="round" />
			<path
				d="M15 17C15 17 17 17 17 17C19.7614 17 22 14.7614 22 12C22 9.23858 19.7614 7 17 7C17 7 15 7 15 7"
				stroke="white"
				strokeLinecap="round"
			/>
		</>
	),
})
