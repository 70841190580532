/* eslint-disable no-undef */
import React, { useMemo } from 'react'
import {
	Flex,
	Link,
	Box,
	Text,
	FormControl,
	FormLabel,
	Input,
	Textarea,
	Checkbox,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	useDisclosure,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	Spinner,
	useToast,
	useBreakpointValue,
} from '@chakra-ui/react'
import { LinkIcon } from '../../../shared/icons/LinkIcon'
import useWindowSize from '../../../hooks/useWindowSize'

export const SectionForm = ({ onOpen }) => {
	const { width } = useWindowSize()
	const toast = useToast()

	const [isSendForm, setIsSendForm] = React.useState(false)
	const [firstName, setFirstName] = React.useState('')
	const [secondName, setSecondName] = React.useState('')
	const [email, setEmail] = React.useState('')
	const [link, setLink] = React.useState('')
	const [desc, setDesc] = React.useState('')
	const [genres, setGenres] = React.useState([])
	const [plots, setPlots] = React.useState([])
	const [isAgree, setIsAgree] = React.useState(false)
	const isMobile = useMemo(() => width < 833, [width])

	const {
		isOpen: isOpenAccess,
		onClose: onCloseAccess,
		onOpen: onOpenAccess,
	} = useDisclosure()
	const {
		isOpen: isOpenLoad,
		onClose: onCloseLoad,
		onOpen: onOpenLoad,
	} = useDisclosure()

	const handleGenreClick = genre => {
		if (genres.includes(genre)) {
			setGenres(genres.filter(g => g !== genre))
		} else {
			setGenres([...genres, genre])
		}
	}
	const handlePlotClick = plot => {
		if (plots.includes(plot)) {
			setPlots(plots.filter(p => p !== plot))
		} else {
			setPlots([...plots, plot])
		}
	}
	const resetForm = () => {
		setFirstName('')
		setSecondName('')
		setEmail('')
		setLink('')
		setDesc('')
		setGenres([])
		setPlots([])
		setIsAgree(false)
	}
	const sendForm = async () => {
		const data = {
			firstName,
			secondName,
			email,
			link,
			desc,
			genres: genres.join(', '),
			plots: plots.join(', '),
		}
		setIsSendForm(true)

		try {
			onOpenLoad()

			await axios.post(
				'https://script.google.com/macros/s/AKfycbyHzNMJdXMWgkgU13vvYcTsAgee89EmLswVq4JvFdObH_MId-uQdlskTumFQTm_dH-6/exec',
				new URLSearchParams(data),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				},
			)

			resetForm()
			setIsSendForm(false)
			onCloseLoad()
		} catch (error) {
			resetForm()
			setIsSendForm(false)
			onCloseLoad()
		}
		toast({
			title: 'Вітаємо!',
			description: 'Ваша заявка успішно відправлена!',
			status: 'success',
			duration: 2000,
			isClosable: true,
		})
	}
	const isError =
		!firstName || !secondName || !email || !link || !desc || !isAgree

	return (
		<section
			style={{
				background: '#F3F3F4',
				padding: useBreakpointValue({
					sm: '0 15px',
					md: '0',
					lg: '0 100px',
				}),
				maxWidth: useBreakpointValue({
					sm: '440px',
					md: '794px',
					lg: '1440px',
				}),
				margin: '0 auto',
			}}
		>
			<Flex
				flexDirection="column"
				alignItems="center"
				width={useBreakpointValue({
					sm: '100%',
					md: '794px',
					lg: '824px',
				})}
				m="auto"
			>
				<a className="anchor" id="form"></a>
				<Text variant="textTitle" textAlign="center" mb="42px">
					Надсилай свою роботу
				</Text>
				{/* <Text
					p="6px 12px"
					background="#DF7A9E"
					width="auto"
					variant="pishiRegular"
					fontSize="16px"
					borderRadius="10px"
					mb="42px"
				>
					ПРИЙОМ РОБІТ РОЗПОЧИНАЄТЬСЯ 5 СЕРПНЯ. НЕ ПРОҐАВ СВІЙ ШАНС!
				</Text> */}
			</Flex>
			<Flex
				background="#fff"
				p={isMobile ? '42px 24px 26px' : '62px'}
				borderRadius="10px"
				width={useBreakpointValue({
					sm: '100%',
					md: '794px',
					lg: '824px',
				})}
				flexDirection="column"
				alignItems="center"
				m="auto"
			>
				<FormControl>
					<Box mb="20px">
						<FormLabel
							fontFamily="Inter-Regular"
							fontSize="16px"
							color="#03060D"
						>
							Вкажи свої особисті дані, будь ласка
						</FormLabel>
						<Flex flexDirection={isMobile ? 'column' : 'row'} gap="14px">
							<Input
								border="1px solid #C4C4C4"
								name="first"
								value={firstName}
								isInvalid={isSendForm && !firstName}
								onChange={e => setFirstName(e.target.value)}
								placeholder="Ім’я"
								mb="14px"
							/>
							<Input
								border="1px solid #C4C4C4"
								name="second"
								placeholder="Прізвище"
								isInvalid={isSendForm && !secondName}
								mb="32px"
								value={secondName}
								onChange={e => setSecondName(e.target.value)}
							/>
						</Flex>
					</Box>
					<Box mb="20px">
						<FormLabel
							fontFamily="Inter-Regular"
							fontSize="16px"
							color="#03060D"
						>
							Та адресу електронної пошти
						</FormLabel>
						<Input
							border="1px solid #C4C4C4"
							name="email"
							type="email"
							placeholder="author@gmail.com"
							mb="32px"
							isInvalid={isSendForm && !email}
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
					</Box>
					<Box mb="20px">
						<FormLabel
							fontFamily="Inter-Regular"
							fontSize="16px"
							color="#03060D"
						>
							Посилання на твій текст на Google Drive. Переконайся, що файл у
							форматі DOCX і відкритий для нас:
						</FormLabel>
						<Flex gap="10px" alignItems="center">
							<LinkIcon
								w="52px"
								h="52px"
								ml="auto"
								stroke="#fff"
								strokeWidth="1"
								fill="transparent"
								background="#DF7A9E"
								p="15px"
								borderRadius="10px"
							/>
							<Input
								border="1px solid #C4C4C4"
								name="email"
								placeholder="https://drive.google.com/..."
								type="text"
								value={link}
								isInvalid={isSendForm && !link}
								onChange={e => setLink(e.target.value)}
							/>
						</Flex>
					</Box>
					<Box mb="32px">
						<FormLabel
							fontFamily="Inter-Regular"
							fontSize="16px"
							color="#03060D"
						>
							Короткий опис сюжету чи анотація до твоєї книги
						</FormLabel>
						<Textarea
							name="desc"
							mb="32px"
							value={desc}
							onChange={e => setDesc(e.target.value)}
							border="1px solid"
							borderColor="#C4C4C4"
							padding="12px 20px 15px 20px"
							borderRadius="10px"
							fontSize="18px"
							isInvalid={isSendForm && !desc}
							fontFamily="Inter-Regular"
							color="#03060D"
							_hover={{ borderColor: '#C4C4C4' }}
						/>
					</Box>
					<Box mb="32px">
						<FormLabel
							fontFamily="Inter-Regular"
							fontSize="16px"
							color="#03060D"
							mb="20px"
						>
							Обери жанр/и
						</FormLabel>
						<Flex
							flexWrap="wrap"
							justifyContent="start"
							gap="8px"
							m={isMobile ? '0 -10px' : '0'}
						>
							<Text
								border={
									genres.includes('Alpha Romance') ? null : '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									genres.includes('Alpha Romance')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handleGenreClick('Alpha Romance')}
							>
								Alpha Romance
							</Text>
							<Text
								border={
									genres.includes('Paranormal Romance')
										? null
										: '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									genres.includes('Paranormal Romance')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handleGenreClick('Paranormal Romance')}
							>
								Paranormal Romance
							</Text>
							<Text
								border={
									genres.includes('Romantic Suspense')
										? null
										: '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									genres.includes('Romantic Suspense')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handleGenreClick('Romantic Suspense')}
							>
								Romantic Suspense
							</Text>
							<Text
								border={
									genres.includes('Fantasy Romance')
										? null
										: '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									genres.includes('Fantasy Romance')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handleGenreClick('Fantasy Romance')}
							>
								Fantasy Romance
							</Text>
						</Flex>
					</Box>
					<Box mb="47px">
						<FormLabel
							fontFamily="Inter-Regular"
							fontSize="16px"
							color="#03060D"
							mb="20px"
						>
							Обери сюжет/и
						</FormLabel>
						<Flex flexWrap="wrap" justifyContent="start" gap="15px">
							<Text
								border={
									plots.includes('Alpha Male') ? null : '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Alpha Male') ? 'textBadgeAccent' : 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Alpha Male')}
							>
								Alpha Male
							</Text>
							<Text
								border={
									plots.includes('Billionaire Romance')
										? null
										: '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Billionaire Romance')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Billionaire Romance')}
							>
								Billionaire Romance
							</Text>
							<Text
								border={
									plots.includes('Secret Billionaire')
										? null
										: '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Secret Billionaire')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Secret Billionaire')}
							>
								Secret Billionaire
							</Text>
							<Text
								border={
									plots.includes('Hate to Love') ? null : '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Hate to Love')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Hate to Love')}
							>
								Hate to Love
							</Text>
							<Text
								border={
									plots.includes('Werewolf Romance')
										? null
										: '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Werewolf Romance')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Werewolf Romance')}
							>
								Werewolf Romance
							</Text>
							<Text
								border={
									plots.includes('Mafia Romance') ? null : '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Mafia Romance')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Mafia Romance')}
							>
								Mafia Romance
							</Text>
							<Text
								border={
									plots.includes('Kick-ass Heroine')
										? null
										: '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Kick-ass Heroine')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Kick-ass Heroine')}
							>
								Kick-ass Heroine
							</Text>
							<Text
								border={
									plots.includes('Forced Marriage') ? null : '1px solid #03060D'
								}
								cursor="pointer"
								variant={
									plots.includes('Forced Marriage')
										? 'textBadgeAccent'
										: 'textBadge'
								}
								fontSize="16px"
								onClick={() => handlePlotClick('Forced Marriage')}
							>
								Forced Marriage
							</Text>
						</Flex>
					</Box>
					<Flex mb="32px">
						<Checkbox
							isChecked={isAgree}
							onChange={() => setIsAgree(!isAgree)}
							width="39px"
							size="lg"
							isInvalid={isSendForm && !isAgree}
							fontSize="39px"
							mr="14px"
						/>
						<Text variant="pishiRegular" color="#03060D">
							Надсилаючи роботу, я погоджуюсь із обробкою моїх персональних
							даних для цілей участі у конкурсі і{' '}
							<Link color="#DF7A9E" onClick={onOpen}>
								правилами та умовами
							</Link>{' '}
							конкурсу.
						</Text>
					</Flex>
					<Box
						borderRadius="4px"
						border="1px solid #03060D"
						background="#161616"
						p={useBreakpointValue({
							sm: '12px 0',
							md: '0',
							lg: '12px 0',
						})}
						textAlign="center"
						width={isMobile ? '100%' : '275px'}
						m="auto"
					>
						<Button
							to="about"
							spy={true}
							smooth={true}
							duration={1000}
							fs="14px"
							color="#fff"
							width="100%"
							fontSize="18px"
							background="transparent"
							onClick={() => {
								setIsSendForm(true)
								if (isError) return
								onOpenAccess()
							}}
							m="auto"
							// disabled
							_hover={{ background: 'transparent' }}
						>
							Надіслати свій текст
						</Button>
					</Box>
				</FormControl>
			</Flex>
			<Modal isOpen={isOpenAccess} onClose={onCloseAccess}>
				<ModalOverlay />
				<ModalContent
					// minW={348}
					w="348px"
					minW="70vw"
					minH="70vh"
					maxH="80vh"
					p={isMobile ? '10px' : '50px'}
					m="auto"
					overflowY="auto"
					borderRadius="8px"
				>
					{/* <ModalHeader></ModalHeader> */}
					<ModalCloseButton />
					<ModalBody>
						РЕЄСТРУЮЧИСЬ У КОНКУРСІ, КОНКУРСАНТ/КА БЕЗОПЛАТНО НАДАЄ ОРГАНІЗАТОРУ
						ВИКЛЮЧНУ, ОБМЕЖЕНУ ЛІЦЕНЗІЮ НА ВИКОРИСТАННЯ, ЦИФРОВУ ПУБЛІКАЦІЮ,
						АДАПТАЦІЮ, В ТОМУ ЧИСЛІ ЦИФРОВУ, ТА ПУБЛІЧНЕ ВИКОНАННЯ ТВОРУ НА
						ТЕРИТОРІЇ УКРАЇНИ, ВІДПОВІДНО ДО МЕТИ, ВКАЗАНОЇ В П. 6.3. ЦЬОГО
						РЕГЛАМЕНТУ НА ВЕСЬ СТРОК ПРОВЕДЕННЯ КОНКУРСУ (А САМЕ ДЛЯ ПРОВЕДЕННЯ
						ОЦІНКИ ТВОРІВ СЕРЕД КОРИСТУВАЧІВ ПЛАТФОРМ ОРГАНІЗАТОРА, ШЛЯХОМ
						НАДАННЯ ТВОРІВ ДЛЯ ПРОЧИТАННЯ ТА ЗБОРУ АНАЛІТИЧНИХ ДАНИХ З МЕТОЮ
						ВИЗНАЧЕННЯ ЇХ ПОТЕНЦІЙНОЇ ПОПУЛЯРНОСТІ СЕРЕД ЧИТАЧІВ. РЕЄСТРУЮЧИСЬ У
						КОНКУРСІ, КОНКУРСАНТ/КА ПІДТВЕРДЖУЄ, ЩО НА ЙОГО/ЇЇ ТВІР НЕ
						РОЗПОВСЮДЖУЮТЬСЯ ЖОДНІ ПРАВА ТРЕТІХ ОСІБ, ЯКІ МОГЛИ Б ОБМЕЖИТИ
						ЙОГО/ЇЇ ПУБЛІКАЦІЮ ТА ВИКОРИСТАННЯ ОРГАНІЗАТОРОМ ТА/АБО ЙОГО/ЇЇ
						ПАРТНЕРОМ У РАМКАХ КОНКУРСУ ТА В ПОДАЛЬШОМУ.
					</ModalBody>

					<ModalFooter
						display="flex"
						flexDirection={isMobile ? 'column' : 'row'}
						gap="14px"
						justifyContent="end"
					>
						<Button colorScheme="blue" mr={3} onClick={onCloseAccess}>
							Закрити
						</Button>
						<Button
							colorScheme="blue"
							mr={3}
							onClick={() => {
								sendForm()
								onCloseAccess()
							}}
						>
							Відправити
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={isOpenLoad}>
				{' '}
				<ModalOverlay d="flex" justifyContent="center" alignItems="center">
					<Spinner size="xl" m="auto" borderWidth="10px" />
				</ModalOverlay>
			</Modal>
		</section>
	)
}
