import s from './styles.module.scss'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import cn from 'classnames'
import { motion } from 'framer-motion'
import useWindowSize from '../../../hooks/useWindowSize'

const Navigation = () => {
  const [currentHash, setCurrentHash] = useState('')
  const [scrollNav, setScrollNav] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const { pathname, hash } = useLocation()
  const navigate = useNavigate()

  const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])

  const toggleMenu = () => {
    setIsOpen(prevState => !prevState);
  };

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleScroll = (hash) => {
    if (pathname === '/') {
      let targetPosition = 0;
  
      if (hash) {
        const section = document.querySelector(hash);
  
        if (section) {
          const yOffset = -110;
          targetPosition = section.offsetTop + yOffset;
        }
      }
  
      window.scrollTo({ top: targetPosition, behavior: 'smooth' });
      closeMenu();
    } else {
      navigate('/');
      setTimeout(() => {
        if (hash) {
          const section = document.querySelector(hash);
          if (section) {
            const yOffset = -110;
            const targetPosition = section.offsetTop + yOffset;
            window.scrollTo({ top: targetPosition, behavior: 'smooth' });
          }
        }
      }, 100);
    }
  };

  const handleCyclicClick = () => {
    if (pathname === '/mydrama') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      closeMenu();
    }
  };

  useEffect(() => {
    const handleResize = () => {
      closeMenu();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [closeMenu]);

  useEffect(() => {
    let ticking = false;

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (window.scrollY >= 80) {
            setScrollNav(true);
          } else {
            setScrollNav(false);
          }
          ticking = false;
        });

        ticking = true;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setCurrentHash(hash);
  }, [hash]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return (
    <>
      <div className={cn(s.navbar, {[s.scrolledNavbar]: scrollNav || isOpen})}>
        <div className={s.container}>
          <div className={s.wrapper}>
            {!((pathname === '/' && isMobile) && !scrollNav && !isOpen) && (
              <Link className={s.hederLogo} to="/" onClick={() => handleScroll()}>
                <img className={s.logoImage} src="/images/common/new_logo.webp" alt="logo" />
                <span className={s.logoText}>HolyWater</span>
              </Link>
            )}

            <nav className={s.navigation}>
              <ul className={s.navList}>
                <li className={s.navItem}>
                  <NavLink
                    to="/#about"
                    onClick={() => handleScroll('#about')}
                    className={cn(s.navLink, {[s.activePink]: currentHash === '#about'})}
                  >
                    About us
                  </NavLink>
                </li>

                <li className={s.navItem}>
                  <NavLink
                    to="/#products"
                    onClick={() => handleScroll('#products')}
                    className={cn(s.navLink, {[s.activePink]: currentHash === '#products'})}
                  >
                    Our Products
                  </NavLink>
                </li>

                <li className={s.navItem}>
                  <NavLink
                    to="/#work"
                    onClick={() => handleScroll('#work')}
                    className={cn(s.navLink, {[s.activePink]: currentHash === '#work'})}
                  >
                    Work with us
                  </NavLink>
                </li>

                <li className={s.navItem}>
                  <NavLink
                    to="/#contact"
                    onClick={() => handleScroll('#contact')}
                    className={cn(s.navLink, {[s.activePink]: currentHash === '#contact'})}
                  >
                    Contacts
                  </NavLink>
                </li>

                {/* <li className={s.navItem}>
                  <NavLink
                    to="/blog"
                    className={({ isActive }) => cn(s.navLink, {[s.activePink]: isActive})}
                  >
                    Blog
                  </NavLink>
                </li> */}

                <li className={s.navItem}>
                  <NavLink
                    to="/mydrama"
                    onClick={handleCyclicClick}
                    className={({ isActive }) => cn(s.navLink, {[s.activeBlue]: isActive})}
                  >
                    My Drama
                  </NavLink>
                </li>
              </ul>
            </nav>

            <div className={s.hamburger} role='button' onClick={toggleMenu}>
              <motion.span
                className={s.bar1}
                animate={isOpen ? { top: '50%', rotate: 45, width: '30px' } : { top: '0%', rotate: 0, width: '30px' }}
                transition={{ duration: 0.3 }}
              ></motion.span>

              <motion.span
                className={s.bar2}
                animate={isOpen ? { opacity: 0 } : { opacity: 1, top: '45%' }}
                transition={{ duration: 0.3 }}
              ></motion.span>

              <motion.span
                className={s.bar3}
                animate={isOpen ? { top: '50%', rotate: -45, width: '30px' } : { top: '90%', rotate: 0, width: '30px' }}
                transition={{ duration: 0.3 }}
              ></motion.span>
            </div>
          </div>
        </div>
      </div>

      <div className={cn(s.mobileMenu, {[s.open]: isOpen})}>
        <nav className={cn(s.mobileNavigation, {[s.open]: isOpen})}>
          <ul className={s.mobileNavList}>
            <li className={s.mobileNavItem}>
              <NavLink
                to="/#about"
                onClick={() => handleScroll('#about')}
                className={cn(s.navLink, {[s.activePink]: currentHash === '#about'})}
              >
                About us
              </NavLink>
            </li>

            <li className={s.mobileNavItem}>
              <NavLink
                to="/#products"
                onClick={() => handleScroll('#products')}
                className={cn(s.navLink, {[s.activePink]: currentHash === '#products'})}
              >
                Our Products
              </NavLink>
            </li>

            <li className={s.mobileNavItem}>
              <NavLink
                to="/#work"
                onClick={() => handleScroll('#work')}
                className={cn(s.navLink, {[s.activePink]: currentHash === '#work'})}
              >
                Work with us
              </NavLink>
            </li>

            <li className={s.mobileNavItem}>
              <NavLink
                to="/#contact"
                onClick={() => handleScroll('#contact')}
                className={cn(s.navLink, {[s.activePink]: currentHash === '#contact'})}
              >
                Contacts
              </NavLink>
            </li>

            {/* <li className={s.mobileNavItem}>
              <NavLink
                to="/blog"
                className={({ isActive }) => cn(s.navLink, {[s.activePink]: isActive})}
              >
                Blog
              </NavLink>
            </li> */}

            <li className={s.mobileNavItem}>
              <NavLink
                to="/mydrama"
                onClick={handleCyclicClick}
                className={({ isActive }) => cn(s.navLink, {[s.activeBlue]: isActive})}
              >
                My Drama
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>
    </>
  )
}

export default Navigation