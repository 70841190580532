export const accordionStyles = {
	baseStyle: {
		container: {
			borderColor: '#323232',
			_last: {
				borderBottomWidth: '1px',
			},
		},
		icon: {
			borderRadius: 9999,
			_hover: {
				bg: 'blackAlpha.100',
			},
		},
	},
	variants: {
		desktop: {
			container: {
				marginBottom: '24px',
				borderRadius: '30px',
				border: '1px solid #323232',
				bg: '#191818',
				_last: {
					borderBottomWidth: '1px',
				},
			},
			button: {
				padding: '28px 24px',

				color: 'white',
				fontFamily: 'Poppins',
				fontSize: '20px',
				lineHeight: '30px',

				_hover: {
					bg: 'initial',
				},
				_focus: {
					boxShadow: 'none',
				},
			},
			panel: {
				padding: '6px 24px 60px',
				fontSize: '15px',
				lineHeight: '25px',
				color: '#B5B5B5',
			},
		},
	},
	defaultProps: {
		variant: 'desktop',
	},
}
