import './styles.scss'
import { StarIcon } from '../../../shared/icons/StarIcon'

const TEXT = [
	'Curiosity and a strong drive for self-awareness',
	'Boldness and a willingness to challenge the status quo',
	'Persistence and a result-oriented mindset',
	'A focus on growth and development',
]
const SectionWork = () => {
	return (
		<section className="section section-works">
			<div className="work-container">
        <div className="title-block">
          <p className="title" style={{ marginBottom: '52px' }}>
            Work with us<a className="anchor" id="work"></a>
          </p>

          <div className="wrapper-think wrapper-think__desktop">
            <h3 className="work-title">
              Think like us?
              <a 
                href='https://jobs.dou.ua/companies/holy-water/vacancies/' 
                target='_blank' 
                rel="noreferrer" 
                className="join-link"
              >
                Join us.
              </a>
            </h3>
            <p className="work-text-second">
              Find out more about our vacancies
            </p>
          </div>
        </div>

        <div className="wrapper-work">
          <div className="wrapper-think-list">
            <p
              className="second-text text-section-about text-section-work"
              style={{ marginBottom: '32px', opacity: 0.9 }}
            >
              HOLYWATER is an innovative, creative
              and challenging community
            </p>

            <p className="work-subtext">We are driven by:</p>
            <ul className="work-text-list">
              {TEXT.map(item => (
                <li key={item} className="work-text-item">
                  <StarIcon />
                  <p className="work-text">{item}</p>
                </li>
              ))}
            </ul>
          </div>

          <div className="wrapper-think wrapper-think__mobile">
            <h3 className="work-title">
              Think like us?
              <a 
                href='https://jobs.dou.ua/companies/holy-water/vacancies/' 
                target='_blank' 
                rel="noreferrer" 
                className="join-link"
              >
                Join us.
              </a>
            </h3>
            <p className="work-text-second">
              Find out more about our vacancies
            </p>
          </div>
        </div>
      </div>
		</section>
	)
}

export default SectionWork
