import { createSlice } from '@reduxjs/toolkit'
import initialState from '../../initialStates/products'

export const productsSlice = createSlice({
	name: 'products',
	initialState,
	reducers: {},
})

export const getProductsArray = state => Object.values(state.products)
export const getProducts = state => state.products

export default productsSlice.reducer
