import s from './styles.module.scss'

const SectionMyDrama = () => {
  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.topContentWrapper}>
          <span className={s.label}>My Drama</span>
          <img 
            className={s.myDramaImage}
            src="/images/myDrama/my_drama_img.webp"
            alt="My Drama app" 
          />
          <p className={s.text}>
            The shift towards short-form vertical series has revolutionized 
            streaming, making the viewing experience not just convenient but deeply 
            immersive. <span>My Drama app</span> capitalizes on this trend.
            <br /><br />
            HOLYWATER&apos;s <span>My Drama app</span> — a streaming 
            video <span>series platform</span> with episodes filmed vertically and 
            presented in one-minute clips.
          </p>
        </div>

        <p className={s.description}>
          <span>My Drama app</span> transforms the best of HOLYWATER&apos;s 
          books and interactive stories into dynamic, immersive video content. 
          After the launch in April 2024, the company reported <span>$4M</span> 🤘 
          in revenue from the app after 4 months.
        </p>
      </div>
    </section>
  )
}

export default SectionMyDrama