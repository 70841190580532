const products = {
	'widget-builder': {
		title: 'Widget builder',
		to: 'widget-builder',
		description: 'Lifestyle App',
		year: '2020',
		img: '/images/common/productsCards/widgetbox.png',
		pageInfo: {
			name: 'Widget builder',
			achivment:
				'Widget builder reached top 1 position in the lifestyle category',
			description1: `It's a single powerful tool that you need to make your phone look special and show your individuality featuring widgets, icon skins, variety of fonts and more.`,
			title: `Widget builder offers our users:`,
			description2: [
				'A variety of themes with custom-made icons, stylish widgets, and wallpapers that suits perfectly, you can find live wallpapers too',
				'Widgets creation and full customization of existing widgets',
				'A custom keyboard with backgrounds and an enormous quantity of fonts which you can use anywhere',
				'Custom charging screens',
				'A custom keyboard for an apple watch to reply to messages',
			],
			arrowRotateDeg1: 105,
			arrowImage1: '/images/common/infoArrowImages/bible.png',
			arrowRotateDeg2: 45,
			arrowImage2: '/images/common/infoArrowImages/mvp.png',
			nextProductName: 'Interactive stories game',
			nextProductLink: 'interactive-stories',
			sliderFontSize: 22,
		},
	},
	'interactive-stories': {
		title: 'Interactive stories game',
		to: 'interactive-stories',
		description: 'Role-Playing',
		year: '2020',
		img: '/images/common/productsCards/my-fantasy.png',
		pageInfo: {
			name: 'Interactive stories game',
			description1: `We are building a Netflix for interactive stories. Our mission is to create a game where our users can embrace and fulfill any fantasy through interactive stories.`,
			achivment: '#1 interactive stories game in Australia',
			title: `More than a million users have enjoyed Interactive stories game. `,
			description2: `Most people are still ashamed of fantasizing about bold or provocative plots. Our mission is to change that.

Interactive stories game interactive stories gives you a chance to throw off the shackles of embarrassment and meet with your deep fantasies. Choose a fantasy you like, flirt with whomever you like, explore your wildest desires and control the plot by yourself! `,
			arrowRotateDeg1: 90,
			arrowImage1: '/images/common/infoArrowImages/bible.png',
			arrowRotateDeg2: 0,
			arrowImage2: '/images/common/infoArrowImages/mvp.png',
			nextProductName: 'ML photo editor',
			nextProductLink: 'ml-photo-editor',
			sliderFontSize: 30,
		},
	},
	'ml-photo-editor': {
		title: 'ML photo editor',
		to: 'ml-photo-editor',
		description: 'Graphics & Design',
		year: '2020',
		img: '/images/common/productsCards/refine.png',
		pageInfo: {
			name: 'ML photo editor',
			description1: `ML photo editor is a photo editor that helps you capture and edit stunning images`,
			title: `1.5M+ users created fantastic images with ML photo editor`,
			description2: `Easy image editing filters will help you to modify your photo in a second. Apply photo filters, or start photo editing wherever you are! Our all-in-one editor gives you all needed tools for photo editing. Just upload your a photo and bring your creativity to life`,
			arrowRotateDeg1: '110',
			arrowImage1: '/images/common/infoArrowImages/bible.png',
			arrowRotateDeg2: '35',
			arrowImage2: '/images/common/infoArrowImages/mvp.png',

			nextProductName: 'Habit builder',
			nextProductLink: 'habit-builder',
			sliderFontSize: 40,
		},
	},
	'habit-builder': {
		title: 'Habit builder & tracker',
		to: 'habit-builder',
		description: 'Productivity',
		year: '2020',
		img: '/images/common/productsCards/habitbox.png',
		pageInfo: {
			name: 'Habit builder & tracker',
			description1: `Habit builder &tracker is a personal helper which is perfect for setting goals, tracking your progress, and motivating yourself to new heights!`,
			title: `1m+ users improved their lifestyle using Habit builder &tracker`,
			description2: `Habit builder &tracker can help you with planning your schedule to create healthy new habits. You can analyze your daily, weekly and monthly progress in one place!`,
			arrowRotateDeg1: '110',
			arrowImage1: '/images/common/infoArrowImages/bible.png',
			arrowRotateDeg2: '35',
			arrowImage2: '/images/common/infoArrowImages/mvp.png',
			nextProductName: 'Romantic books app',
			nextProductLink: 'romantic-books',
			sliderFontSize: 28,
		},
	},
	holyspace: {
		title: 'Christian stories and meditations',
		// to: 'holyspace',
		description: 'Lifestyle App',
		year: '2020',
		img: '/images/common/productsCards/holyspace.png',
		pageInfo: {
			name: 'Widgetbox',
			description1: `It's a single powerful tool that you need to make your phone look special and show your individuality featuring widgets, icon skins, variety of fonts and more`,
			title: `Several principles of working with WidgetBox from Holy water`,
			description2: `Holy Water is one of the fastest-growing projects within the Genesis ecosystem. Our mission is to create an opportunity for the team to achieve their goals and potential to the fullest through building successful business.
            
Our mission is to create an opportunity for the team to achieve their goals and potential to the fullest through building successful business.`,
			arrowRotateDeg1: '0',
			arrowImage1: '/images/common/infoArrowImages/bible.png',
			arrowRotateDeg2: '0',
			arrowImage2: '/images/common/infoArrowImages/mvp.png',
			nextProductName: 'Widgetbox',
			nextProductLink: 'widgetbox',
		},
		isComingSoon: true,
	},
	'romantic-books': {
		title: 'Romantic books app',
		to: 'romantic-books',
		description: 'Books',
		year: '2020',
		img: '/images/common/productsCards/desire.png',
		pageInfo: {
			name: `Romantic 
books app`,
			description1: `Romantic books app is the world of best romance for all tastes, which will make every reading evening unforgettable.`,
			title: `Our goal is to create a platform where:`,
			description2: [
				'Every talented and hard-working writer can publish their stories and reach out to millions of readers',
				'Every reader will find a story which will evoke true passion in them.',
				'We believe that Romantic books app is something that evokes the strongest feelings and emotions and inspires people to make their dreams come true.',
			],
			arrowRotateDeg1: '110',
			arrowImage1: '/images/common/infoArrowImages/bible.png',
			arrowRotateDeg2: '35',
			arrowImage2: '/images/common/infoArrowImages/mvp.png',
			nextProductName: 'Widget builder',
			nextProductLink: 'widget-builder',
			sliderFontSize: 35,
		},
	},
}

export default products
