import { createSlice } from '@reduxjs/toolkit'

import initialState from '../../initialStates/holyValues'

export const holyValuesSlice = createSlice({
	name: 'holyValues',
	initialState,
	reducers: {},
})

export const getHolyValues = state => state.holyValues

export default holyValuesSlice.reducer
