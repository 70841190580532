import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import MainNewPage from './screens/mainNewPage'
import { NotFound } from './screens/notFound/component'
import { Sitemap } from './screens/sitemap/component'
import './styles/styles.scss'

import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import 'swiper/scss/autoplay'
import 'swiper/css/scrollbar'
import 'swiper/css/navigation'

import Pishi from './screens/pishi/component'
import MyDramaPage from './screens/myDramaPage/component'

const App = () => {
	const { hash, pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)

		switch (pathname) {
			case '/': {
				document.title =
					'HOLYWATER: Media-Tech Co, Books, Audiobooks, Series, 20M+ users'
				return
			}
			case '/company': {
				document.title = 'HolyWater | Company'
				return
			}
			case '/team': {
				document.title = 'HolyWater | Team'
				return
			}
			case '/careers': {
				document.title = 'HolyWater | Careers'
				return
			}
			case '/products': {
				document.title = 'HolyWater | Products'
				return
			}
			case '/contact-holy-water': {
				document.title = 'HolyWater | Contact HolyWater'
				// return
			}
			// case '/save-ukraine': {
			// 	document.title = 'World save Ukraine'
			// }
		}
	}, [pathname])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])

	return (
		<>
			<Routes>
				{/* <Route path="/" element={<MainPage />} /> */}
				<Route path="/" element={<MainNewPage />} />
				<Route path="/sitemap" element={<Sitemap />} />
				<Route path="/pyshy" element={<Pishi />} />
				<Route path="/mydrama" element={<MyDramaPage />} />
				<Route path="*" element={<NotFound />} />
				{/* <Route path="/company" element={<CompanyPage />} /> */}
				{/* <Route path="/team" element={<TeamPage />} /> */}
				{/* <Route path="/careers" element={<CareersPage />} /> */}
				{/* <Route path="/products" element={<ProductsPage />} /> */}
				{/* <Route path="/products/:productName" element={<ProductPage />} /> */}
				{/* <Route path="/contact-holy-water" element={<ContactHolyWaterPage />} /> */}
				{/* <Route path="/save-ukraine" element={<CharityPage />} /> */}
				{/* <Route path="*" element={<Navigate to="/" />} /> */}
			</Routes>
		</>
	)
}

export default App
