export const inputStyles = {
	baseStyle: {},
	sizes: {
		md: {
			field: {
				height: '48px',
				padding: '12px 16px',
			},
		},
		sm: {
			field: {
				height: '32px',
				padding: '7px 12px',
			},
		},
	},
	variants: {
		default: {
			field: {
				bg: 'transparent',
				border: '1px solid #C4C4C4',
				borderColor: '#C4C4C4',
				padding: '12px 20px 15px 20px',
				borderRadius: '10px',
				fontSize: '18px',
				fontWeight: 400,
				fontFamily: 'Inter-Regular',
				lineHeight: '140%',
				color: '#03060D',
				// _hover: {
				// 	borderColor: '#f3f3f8',
				// },
				_invalid: {
					borderColor: '#ff218c',
					bg: 'rgba(255, 33, 140, 0.04)',
				},
				_focus: {
					outline: 'none',
					borderColor: '#644ded',
					bg: '#FFFFFF',
				},
				'&::placeholder': {
					color: '#C4C4C4',
				},
			},
		},
	},
	defaultProps: {
		size: 'md',
		variant: 'default',
	},
}
