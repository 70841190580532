import './styles.scss'
import { ArrowIcon } from '../../../shared/icons/ArrowIcon'
import { useNavigate } from 'react-router-dom'

const MainFooter = ({ buttonColor }) => {
	const navigate = useNavigate()

	return (
		<>
			<footer className="page-footer">
				<div className="contact-info">
					<p id="contact" className="contact-title">
						<span>Let’s</span> <span>connect</span>
					</p>

          <div className="content-block">
            <p className="contact-text">
              Feel free to reach out if you want to work & collaborate with
              us, or simply have a chat.
            </p>
            <a className="footer-button" href="mailto:info@holywater.tech" style={{ backgroundColor: buttonColor || ''}}>
              <span>ConTact us <span><ArrowIcon /></span></span>
            </a>
          </div>

					<div className="footer-bottom">
						<button
							className="sitemap"
							name="sitemap"
							onClick={() => navigate('/sitemap')}
						>
							Sitemap
						</button>
						<div className="copywriting-text">
							<p>With love from Ukraine</p>
							<span>© {new Date().getFullYear()}</span>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export { MainFooter }
