import s from './styles.module.scss'
import { ArrowIcon } from '../../../shared/icons/ArrowIcon'

const SectionPartner = () => {
  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.headingBlock}>
          <span className={s.label}>Partner</span>

          <div className={s.headingContent}>
            <h2 className={s.heading}>Partner with My Drama</h2>
            <p className={s.description}>
              <span>My Drama app</span> is always looking for partners with whom to collaborate. 
              Whether you&apos;re a content creator, a marketer, or a tech developer, there are 
              numerous opportunities to work with the My Drama team and bring your ideas to life 
              on this groundbreaking platform.
              <br /><br />
              My Drama offers a range of collaboration options that can help you reach new audiences 
              and achieve your goals. Our <span>vertical video platform</span> is the perfect place 
              to showcase your creativity, and we&apos;re excited to explore new possibilities together.
            </p>
            <img 
              className={s.appImage}
              src="/images/myDrama/partner_img.webp"
              alt="My Drama app" 
            />
          </div>
        </div>

        <div className={s.bottomBlock}>
          <p className={s.text}>
            <span>HOLYWATER and My Drama</span> are open to cooperation with creative minds: authors, 
            screenwriters, directors, producers, performers, etc. Reach out through our 
            email: <a href="mailto:info@holywater.tech">info@holywater.tech</a>, and let&apos;s start 
            a conversation about how we can work together to push the boundaries of what&apos;s possible 
            in storytelling.
          </p>
          <a href="mailto:info@holywater.tech" className={s.button}><span>ConTact us <span><ArrowIcon /></span></span></a> 
        </div>
      </div>
    </section>
  )
}

export default SectionPartner