import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import initialState from '../../initialStates/offers'

export const fetchOffers = createAsyncThunk(
	'offers/fetchOffers',
	async () =>
		await fetch('https://jsonplaceholder.typicode.com/users').then(response =>
			response.json(),
		),
)

export const offersSlice = createSlice({
	name: 'offers',
	// initialState: [],
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(fetchOffers.fulfilled, (state, action) => {
			// state = action.payload;
			// return state;
		})
	},
})

export const getoffers = state => state.offers

// getOfferInfo

export default offersSlice.reducer
