const achivments = [
	{
		id: 0,
		year: 2020,
		list: [
			'2 products',
			'7 people',
			'10000 users',
			'Assembled a team',
			'Launched 1 iOS app and 1 game',
		],
	},
	{
		id: 1,
		year: 2021,
		list: [
			'10+ products',
			'60 people',
			'10000000 users',
			'#1 habit tracker in the world',
			'#1 in lifestyle category',
			'1 million users played My Fantasy',
			'Started ML and Blockchain R&D',
			'200+ hours of crazy parties together',
		],
	},
]

export default achivments
