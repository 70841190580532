import React from 'react'
import { createIcon } from '@chakra-ui/react'

export const LocationIcon = createIcon({
	viewBox: '0 0 36 41',
	path: (
		<>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.03613 17.6702C2.06572 8.96165 9.14939 1.92595 17.858 1.95554C26.5665 1.98512 33.6022 9.0688 33.5726 17.7774V17.9559C33.4655 23.6168 30.3047 28.8491 26.4296 32.9385C24.2134 35.2398 21.7386 37.2772 19.0544 39.01C18.3367 39.6309 17.2721 39.6309 16.5544 39.01C12.5529 36.4055 9.04091 33.1172 6.17909 29.2955C3.62841 25.9629 2.18023 21.918 2.03613 17.7238L2.03613 17.6702Z"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="17.8047"
				cy="17.9737"
				r="5.0537"
				stroke="white"
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</>
	),
})
