import { Image, Link } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Scrollbar, Autoplay } from 'swiper'
import useWindowSize from '../../../hooks/useWindowSize'
import './swiperStyles.css'

const heroImgs = [
	'/images/pishi/mob/hero1.png',
	'/images/pishi/mob/hero2.png',
	'/images/pishi/mob/hero3.png',
	'/images/pishi/mob/hero4.png',
]
const heroImgsDesc = [
	'/images/pishi/desc/hero1.png',
	'/images/pishi/desc/hero2.png',
	'/images/pishi/desc/hero3.png',
	'/images/pishi/desc/hero4.png',
]
const SectionHero = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 833, [width])
	const imgHero = isMobile ? heroImgs : heroImgsDesc
	return (
		<section style={{ marginTop: isMobile ? '10px' : '100px' }}>
			<Link className="anchor" id="hero"></Link>
			<Swiper
				// slidesPerView="auto"
				speed={500}
				// freeMode={true}
				spaceBetween={0}
				loop
				autoplay={{ delay: 4000, disableOnInteraction: false }}
				pagination={{ type: 'progressbar' }}
				modules={[Pagination, Scrollbar, Autoplay]}
				// className="about-slider"
				// modules={[Pagination]}
				allowSlidePrev={true}
				allowSlideNext={true}
			>
				{imgHero.map(img => (
					<SwiperSlide key={img}>
						<Image src={img} objectFit="contain" width="100vw"></Image>
					</SwiperSlide>
				))}
			</Swiper>
		</section>
	)
}

export default SectionHero
