import { Flex, LinkBox } from '@chakra-ui/react'
import React, { useMemo } from 'react'
import { Pagination, Scrollbar } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import './styles.scss'
import { ArrowIcon } from '../../../shared/icons/ArrowIcon'
import useWindowSize from '../../../hooks/useWindowSize'

const PRODUCTS = [
	{
		img: '/images/mainPage/drama-product.png',
		title: 'My Drama',
		link: 'https://play.google.com/store/apps/details?id=com.dashdramas&referrer=af_tranid%3DsM2_YAz-4oNKxGxawIyOfw',
		linkIOS:
			'https://apps.apple.com/us/app/my-drama-amazing-episodes/id6498713494 ',
		desc: 'Experience the thrill of unique and original short dramas and films based on our best books and interactive stories, each crafted to last around a minute, ideal for entertainment while on the move. Perfect for moments during your commute, relaxing at home, or while enjoying a meal out, MY DRAMA offers a quick escape into quality storytelling.',
		tag: 'VERTICAL VIDEO SERIES PLATFORM',
	},
	{
		img: '/images/mainPage/passion.png',
		title: 'MY PASSION',
		subTitle: '#1 ROMANTIC BOOKS APP IN 10+ COUNTRIES',
		link: 'https://play.google.com/store/apps/details?id=gm.passion.app&hl=en',
		linkIOS:
			'https://apps.apple.com/us/app/passion-romance-fiction-books/id1568149521',
		desc: 'PASSION is the world of books and audiobooks for all tastes, making your reading evening unforgettable. Personalized algorithm delivers story suggestions based on your interests and reading behavior.',
		tag: 'ROMANTIC BOOKS APP',
	},
	{
		img: '/images/mainPage/ai-platform.png',
		title: 'AI COMPANION',
		// subTitle: '#1 INTERACTIVE STORIES GAME WORLDWIDE BY INSTALLS',
		// link: 'https://www.my-imagination.ai/explore',
		// linkIOS: 'https://www.my-imagination.ai/explore',
		desc: 'Step into a world where your favorite fictional characters come to life! With AI Сompanion, you can chat with beloved heroes, heroines, and villains from our books, series, and interactive stories. Build deep connections, learn more about their worlds, and experience personalized conversations that bring your imagination to life. Dive into your favorite stories like never before! SOON!',
		tag: 'Interactive Character AI-CompanioN',
	},
]

const SectionProduct = () => {
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])
  const isTablet = useMemo(() => width >= 768 && width < 1024, [width])

	const handleClick = (link, linkIOS) => {
		const isAndroid = navigator.userAgent.match(/Android/i)
		window.open(isAndroid ? link : linkIOS, '_blank')
	}
	return (
		<section className="section section-products">
			<div className="product-container">
        <p className="title" style={{ fontSize: '24px' }}>
          Our Products<a className="anchor" id="products"></a>
        </p>
        {(isMobile || isTablet) ? (
          <Swiper
            slidesPerView="auto"
            speed={1500}
            freeMode={true}
            spaceBetween={20}
            loop
            // pagination={{ type: 'progressbar' }}
            className="about-slider"
            modules={[Pagination, Scrollbar]}
            allowSlidePrev={true}
            allowSlideNext={true}
            scrollbar={{ draggable: true, dragSize: 110 }}
          >
            {PRODUCTS.map(item => (
              <SwiperSlide key={item.tag} style={{ width: 'auto' }}>
                <LinkBox
                  as="div"
                  className="section-product wrapper-product-card"
                  mb={5}
                  onClick={() =>
                    item.link && handleClick(item.link, item.linkIOS)
                  }
                >
                  <div className="product-image">
                    {/* <LinkOverlay isExternal href={item.link}> */}
                    <img
                      src={item.img}
                      alt="my-fantasy"
                      style={{ display: 'block' }}
                    />
                    {/* </LinkOverlay> */}
                  </div>
                  <div className="product-desc-wrapper">
                    <Flex justifyContent="space-between" alignItems="center">
                      <h4 className="product-title">{item.title}</h4>
                      <button
                        className="product-button"
                        name={`product button ${item.title}`}
                      >
                        <ArrowIcon />
                      </button>
                    </Flex>
                    {item.subTitle && (
                      <p className="product-info">{item.subTitle}</p>
                    )}
                    <p className="product-desc">{item.desc}</p>
                  </div>
                  <p className="product-tag">{item.tag}</p>
                </LinkBox>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <ul className="product-list-desktop">
            {PRODUCTS.map(item => (
              <li
                key={item.tag}
                className="section-product wrapper-product-card product-item-wrapper"
                onClick={() => item.link && handleClick(item.link, item.linkIOS)}
              >
                <div className="product-image">
                  <img
                    src={item.img}
                    alt="my-fantasy"
                    style={{ display: 'block' }}
                  />
                </div>
                <div className="product-desc-tag">
                  <div className="product-desc-wrapper">
                    <Flex justifyContent="space-between" alignItems="center">
                      <h4 className="product-title">
                        {item.title}
                      </h4>
                      <button className="product-button">
                        <ArrowIcon />
                      </button>
                    </Flex>
                    {item.subTitle && (
                      <p className="product-info">{item.subTitle}</p>
                    )}
                    <p className="product-desc">{item.desc}</p>
                  </div>
                  <p className="product-tag-desktop">{item.tag}</p>
                </div>
                <p className="product-tag">{item.tag}</p>
              </li>
            ))}
          </ul>
        )}
      </div>
		</section>
	)
}

export default SectionProduct
