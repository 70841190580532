import s from './styles.module.scss'

const ITEMS = [
  {
    value: "30",
    text: (
      <>
        The app has <span>30 shows available.</span> By the end of 2024, My Drama app 
        plans to increase the amount of content up to 100 series.
      </>
    )
  },
  {
    value: "+1m",
    text: (
      <>
        The most popular series in the app, Shy Beauty and Billionaire Beast, has already 
        been viewed by more than <span>1 million people.</span>
      </>
    )
  },
  {
    value: "50",
    text: (
      <>
        By the end of 2024, the My Drama team plans to reach <span>20 million</span> viewers 
        worldwide, entering <span>50 countries.</span>
      </>
    )
  },
  {
    value: "#1",
    text: <>The app ranks #1 among European and American players in its niche.</>
  },
  {
    value: "10",
    text: <>It is in the top 10 by revenue in the niche.</>
  },
  {
    value: "5",
    text: <>It ranks in the top 5 for Android installs.</>
  }
];

const SectionOutcomes = () => {
  return (
    <section className={s.section}>
      <div className={s.container}>
        <div className={s.headingBlock}>
          <span className={s.label}>Outcomes</span>
          <h2 className={s.heading}>My&nbsp;Drama outcomes:</h2>
        </div>

        <div className={s.gridContainer}>
          {ITEMS.map(({ value, text }) => (
            <div key={value} className={s.gridItem}>
              <span className={s.value}>{value}</span>
              <p className={s.text}>
                {text}
              </p>
            </div>
          ))}
        </div>

        <p className={s.description}>
          The video streaming market, in which My Drama competes, is estimated at $500 billion. 
          It is estimated to grow to $1.7 trillion by 2030. Vertical video series are the 
          future of streaming, driven by the increasing dominance of smartphones as the 
          primary device for media consumption.
        </p>
      </div>
    </section>
  )
}

export default SectionOutcomes